import './messageCancel.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type MessageCancelProps = {
    activeProps: boolean,
    reloadProps: boolean,
    url?: string
}

export default function MessageCancel({activeProps, reloadProps, url}: MessageCancelProps) {
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [reload, setReload] = useState(false)

    useEffect(() => {
        setActive(activeProps);
        setReload(reloadProps);
    }, [activeProps, setActive]);

    const handleYes = () => {
        if (reload === true) {
            window.location.reload();
        } else {
            navigate(url || '/');
        }
    }

    const handleNo = () => {
        setActive(false);
    }

    return (
        <div className={active === true ? 'message-cancel' : 'message-cancel-none'}>
            <div className='div-button-message'>
                <h4 className='text-cancel pt-2'>Tem certeza que deseja cancelar?</h4>
            </div>
            <div className='div-button-message mb-1'>
                <button className='button-message' onClick={handleNo}>não</button>
                <button className='button-message' onClick={handleYes}>sim</button>
            </div>
        </div>
    );
}