import React, { useEffect, useState } from 'react';
import ProfileEdit from './ProfileEdit';
import { profile } from '../api/profile';

interface User {
  id: number,
  name: string,
  email: string,
  cpf: string,
  office: string,
  company: string,
  cnpj: string,
  address_cep_address: string,
  endereco2: string,
  cep_address: string,
  cep_cep_address: string,
  num_cep_address: number,
  complement: string,
  neighborhood_cep_address: string,
  city_cep_address: string,
  uf_cep_address: string,
  country_cep_address: string,
  phone: string,
}

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<User>(
    {
      id: 0,
      name: '',
      email: '',
      cpf: '',
      office: '',
      company: '',
      cnpj: '',
      address_cep_address: '',
      endereco2: '',
      cep_address: '',
      cep_cep_address: '',
      num_cep_address: 0,
      complement: '',
      neighborhood_cep_address: '',
      city_cep_address: '',
      uf_cep_address: '',
      country_cep_address: '',
      phone: '',
    }
  );

  useEffect(() => {
    const fetchData = async () => {
        try {
            const profileRequest = await profile('profile');

            setUser({
              id: profileRequest['cliente']['id'],
              name: profileRequest['cliente']['name'],
              email: profileRequest['cliente']['email'],
              cpf: profileRequest['cliente']['cpf'],
              office: profileRequest['cliente']['office'],
              company: profileRequest['cliente']['company'],
              cnpj: profileRequest['cliente']['cnpj'],
              address_cep_address: profileRequest['cliente']['address_cep_address'],
              endereco2: '',
              cep_cep_address: profileRequest['cliente']['cep_cep_address'],
              cep_address: profileRequest['cliente']['cep_address'],
              num_cep_address: profileRequest['cliente']['num_cep_address'],
              complement: profileRequest['cliente']['complement_cep_address'],
              neighborhood_cep_address: profileRequest['cliente']['neighborhood_cep_address'],
              city_cep_address: profileRequest['cliente']['city_cep_address'],
              uf_cep_address: profileRequest['cliente']['uf_cep_address'],
              country_cep_address: profileRequest['cliente']['country_cep_address'],
              phone: profileRequest['cliente']['phone'],
            });
        } catch (error) {
            console.error('Erro ao buscar perfil:', error);
        }
    };

    fetchData();
  }, [])

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="mt-20 relative text-left p-4">
      {isEditing ? (
        <ProfileEdit user={user} onCancel={handleCancel} />
      ) : (
        <>
          <button 
            className="absolute top-4 right-4 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
            onClick={handleEditClick}
          >
            Editar
          </button>
          <h1 className="text-2xl font-bold mb-4">Perfil</h1>
          <p className="mb-6">Detalhes do perfil de {user.name}.</p>
          <div className="border-transparent bg-white inline-block mb-4">
            <h2 className="text-lg font-semibold mb-2">Informações Pessoais</h2>
            <p><strong>Nome:</strong> {user.name}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Tipo de Perfil:</strong> {user.office}</p>
          </div>
          <hr />
          <div className="border-transparent bg-white inline-block mb-4 mt-5">
            <h2 className="text-lg font-semibold mb-2">Informações da Empresa</h2>
            <p><strong>Empresa:</strong> {user.company}</p>
            <p><strong>Endereço:</strong> {user.address_cep_address}, {user.num_cep_address} - {user.city_cep_address}, {user.uf_cep_address}</p>
            <p><strong>Telefone:</strong> {user.phone}</p>
          </div>
          <hr />
        </>
      )}
    </div>
  );
};

export default Profile;
