import React from "react";
import axios from 'axios';

export async function cnpjUser() {
    try {
        const reponseActive = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user-cnpj/',
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (reponseActive.status === 200) {
            return reponseActive.data;
        }
    } catch (error) {
        return []
    }
}