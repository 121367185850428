import React, { MouseEventHandler, useState } from 'react';

interface User {
  name: string,
  email: string,
  profileType: string,
  company: string,
  endereco1: string,
  endereco2: string,
  phone: string,
  image: string,
  date: string,
  title: string
}

interface Report { 
  user: User, onSave: Function, onCancel: MouseEventHandler<HTMLButtonElement> 
}

const ReportsEdit = ({ user, onSave, onCancel }: Report) => {
  const [editedUser, setEditedUser] = useState(user);
  const [image, setImage] = useState(user.image || null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        setImage(result);
        setEditedUser((prevState) => ({ ...prevState, image: result }));
      }
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave(editedUser);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block mb-2">Título:</label>
        <input
          type="text"
          name="title"
          value={editedUser.title}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
        <div>
        <label className="block mb-2">Imagem:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
        {image && <img src={image} alt="Selecionada" className="mt-4 w-full max-h-64 object-cover" />}
      </div>
      </div>
      <div>
        <label className="block mb-2">Nome:</label>
        <input
          type="text"
          name="name"
          value={editedUser.name}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div>
        <label className="block mb-2">Empresa:</label>
        <input
          type="text"
          name="company"
          value={editedUser.company}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div>
        <label className="block mb-2">Email:</label>
        <input
          type="email"
          name="email"
          value={editedUser.email}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div>
        <label className="block mb-2">Data:</label>
        <input
          type="text"
          name="date"
          value={editedUser.date}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div>
        <label className="block mb-2">Tipo de Perfil:</label>
        <input
          type="text"
          name="profileType"
          value={editedUser.profileType}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div>
        <label className="block mb-2">Endereço:</label>
        <input
          type="text"
          name="endereco1"
          value={editedUser.endereco1}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div>
        <label className="block mb-2">Telefone:</label>
        <input
          type="text"
          name="phone"
          value={editedUser.phone}
          onChange={handleChange}
          className="border border-gray-300 px-4 py-2 rounded w-full"
        />
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={onCancel}
          className="border border-gray-300 text-red-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
        >
          Salvar
        </button>
      </div>
    </form>
  );
};

export default ReportsEdit;
