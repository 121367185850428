// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-certification {
    width: 80%;
}

.area-message-certification {
    display: flex;
    width: 70%;
    justify-content: center;
}

@media (max-width: 1100px) {
    .container-certification {
        width: 100%;
    }

    .section-home {
        padding-right: 10px;
    }

    .img-search {
        display: none;
      }
    
    .circle-user {
    margin-left: 40px !important;
    }

    .col-profile {
    margin: 0;
    }

    .sidebar-home {
        display: flex;
        height: 20%;
        width: 250px;
        position: relative;
        left: 0;
        background-color: #ffffff;
        padding-top: 15px;
        flex-direction: column;
        margin-top: 5px;
        justify-content: space-between;
    }

    .container-sidebar {
    display: none;
    }

    .background-img-amz {
    width: 100%;
    }

    .col-text-home {
    width: 100%;
    }

    .area-message-certification {
        display: flex;
        width: 100%;
        height: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Certification/certification.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,aAAa;MACf;;IAEF;IACA,4BAA4B;IAC5B;;IAEA;IACA,SAAS;IACT;;IAEA;QACI,aAAa;QACb,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,OAAO;QACP,yBAAyB;QACzB,iBAAiB;QACjB,sBAAsB;QACtB,eAAe;QACf,8BAA8B;IAClC;;IAEA;IACA,aAAa;IACb;;IAEA;IACA,WAAW;IACX;;IAEA;IACA,WAAW;IACX;;IAEA;QACI,aAAa;QACb,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".container-certification {\n    width: 80%;\n}\n\n.area-message-certification {\n    display: flex;\n    width: 70%;\n    justify-content: center;\n}\n\n@media (max-width: 1100px) {\n    .container-certification {\n        width: 100%;\n    }\n\n    .section-home {\n        padding-right: 10px;\n    }\n\n    .img-search {\n        display: none;\n      }\n    \n    .circle-user {\n    margin-left: 40px !important;\n    }\n\n    .col-profile {\n    margin: 0;\n    }\n\n    .sidebar-home {\n        display: flex;\n        height: 20%;\n        width: 250px;\n        position: relative;\n        left: 0;\n        background-color: #ffffff;\n        padding-top: 15px;\n        flex-direction: column;\n        margin-top: 5px;\n        justify-content: space-between;\n    }\n\n    .container-sidebar {\n    display: none;\n    }\n\n    .background-img-amz {\n    width: 100%;\n    }\n\n    .col-text-home {\n    width: 100%;\n    }\n\n    .area-message-certification {\n        display: flex;\n        width: 100%;\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
