import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Home from './pages/Home';
import HomePage from './pages/HomePage';
import FieldEvaluation from './pages/FieldEvaluation';
import Steps from './pages/Steps';
import Reports from './pages/Reports';
import Users from './pages/Users';
import UsersProfile from './pages/UsersProfile';
import Profile from './pages/Profile';
import ProfileEdit from './pages/ProfileEdit';
import ReportProblem from './pages/ReportProblem';
import Settings from './pages/Settings';
import Login from './pages/Login';
import NewFE from './pages/NewFE';
import FinalFE from './pages/FinalFE';
import ConsultFE from './pages/ConsultFE';
import ReportsView from './pages/ReportsView';
import ReportsEdit from './pages/ReportsEdit';
import ReportsConsult from './pages/ReportsConsult';
import ReportsFinal from './pages/ReportsFinal';
import RegisterClient from './pages/RegisterClient/registerClient';
import ResetPasswordScreen from './pages/resetPassword';
import CompleteResetPasswordScreen from './pages/completeResetPassword';
import ViewStage from './pages/ViewStage/viewStage';
import RegisterSpreadsheets from './pages/RegisterSpreadsheets/registerSpreadsheets';
import SimulationAudiction from './pages/RegisterSpreadsheets/simulationAudiction';
import Simulation from './pages/RegisterSpreadsheets/simulation';
import Monitoring from './pages/Monitoring/monitoring';
import CategorieSimulation from './pages/RegisterSpreadsheets/simulationSelectionCategorie';
import Certification from './pages/Certification/certification';
import Relatory from './pages/Relatory/relatory';
import Certificate from './pages/Certificate';
import FormTest from './pages/FormTest';

const ProtectedRoutes = () => {
  const location = useLocation();

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto">
          <Routes location={location}>
            <Route path="/home-page" element={<HomePage />} />
            <Route path="/field-evaluation" element={<FieldEvaluation />} />
            <Route path="/consultFE" element={<ConsultFE />} />
            <Route path="/finalFE" element={<FinalFE />} />
            <Route path="/steps" element={<Steps />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/users" element={<Users />} />
            {/* <Route path="/users-profile" element={<UsersProfile />} /> */}
            <Route path="/profile" element={<Profile />} />
            {/* <Route path="/profile-edit" element={<ProfileEdit />} /> */}
            <Route path="/report-problem" element={<ReportProblem />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/newFE" element={<NewFE />} />
            <Route path="/reports-view" element={<ReportsView />} />
            {/* <Route path="/reports-edit" element={<ReportsEdit />} /> */}
            <Route path="/reports-consult" element={<ReportsConsult />} />
            <Route path="/reports-final" element={<ReportsFinal />} />
            <Route path="/form-test" element={<FormTest />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<ProtectedRoutes />} />
        <Route path='/register' element={<RegisterClient func='client' url='/login' />} />
        <Route path='/register-profile' element={<RegisterClient func='admin' url='/users' />} />
        <Route path='/send-email' element={<ResetPasswordScreen />} />
        <Route path='/reset-password/:id/:token' element={<CompleteResetPasswordScreen />} />
        <Route path='/:name_table/:id' element={<ViewStage />} />
        <Route path='/spreadsheet/:id' element={<RegisterSpreadsheets />} />
        <Route path='/monitoring' element={<Monitoring />} />
        <Route path='/certification' element={<Certification />} />
        <Route path='/simulation' element={<Simulation />} />
        <Route path='/simulation/stamp/:id' element={<CategorieSimulation />} />
        <Route path='/simulation/stamp/category/:id' element={<SimulationAudiction />} />
        <Route path='/create-relatory' element={<Relatory />} />
        <Route path='/relatory/:id' element={<Relatory />} />
        <Route path='/certificate/:id/:stageId' element={<Certificate />} />
      </Routes>
    </Router>
  );
};

export default App;
