import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/logout';

type HeaderProps = {
  onChangeSearch?: any,
}

const Header = ({onChangeSearch}: HeaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, right: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    if (isModalOpen && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: buttonRect.bottom,
        right: window.innerWidth - buttonRect.right,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        buttonRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  const handleNavigation = (path: string) => {
    setIsModalOpen(false);
    navigate(path);
  };

  const handleClickLogout = async (event: any) => {
      event.preventDefault();
      
      const responseLogout: any = await logout();

      if (responseLogout.status === 200) {
          localStorage.clear();
          navigate('/login');
      }
  }

  return (
    <div className="fixed top-0 left-0 right-0 md:left-64 h-16 bg-white text-black flex flex-col z-10">
      <div className="flex items-center px-4 h-full">
        <div className="flex-1">
          <input
            type="text"
            placeholder="Pesquisar..."
            className="p-2 w-full md:w-1/2 lg:w-1/3 rounded bg-transparent text-gray-500 outline-none focus:ring-0"
            onChange={onChangeSearch}
          />
        </div>
        <div className="relative flex items-center">
          <button
            ref={buttonRef}
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="ml-4 p-2 bg-transparent rounded"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-10">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </button>
          {isModalOpen && (
            <div
              ref={modalRef}
              className="absolute bg-white text-black rounded shadow-lg z-30"
              style={{ top: `${modalPosition.top}px`, right: `${modalPosition.right}px` }}
            >
              <ul>
                <li className="mb-2 p-4 hover:bg-gray-200">
                  <button onClick={() => handleNavigation('/profile')} className="text-black">Perfil</button>
                </li>
                <li className="mb-2 p-4 hover:bg-gray-200">
                  <button onClick={() => handleNavigation('/settings')} className="text-black">Configurações</button>
                </li>
                <li className="mb-2 p-4 hover:bg-gray-200">
                  <button onClick={handleClickLogout} className="text-black">Sair</button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <hr className="border-green-700" />
    </div>
  );
};

export default Header;
