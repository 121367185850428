import axios from 'axios';

export async function stamps() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/stamps/',
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function stampsSimple() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/stamps/simple/',
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function stampsFiltred(id: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/stamps/', {
            id: id
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function stampsSimpleFiltred(id: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/stamps/simple/', {
            id: id
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}