import React, { useState } from 'react';
import { sendReport } from '../api/report';
import { registerUserLogs } from '../api/userLogs';

const ReportProblem = () => {
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const responseReport: any = await sendReport(email, description);
    if (responseReport.status === 200) {
      registerUserLogs("Reportou um problema.");
      setSuccessMessage('Sua mensagem foi enviada com sucesso!');
      // limpa os campos do formulário
      setDescription('');
      setEmail('');
    } else {
      alert('Requisição não foi bem sucedida. Por favor, verifique o texto e tente novamente.')
    }
  };

  return (
    <div className="mt-20 flex flex-col items-center p-4">
      <h1 className="text-2xl font-bold mb-4">Reportar um Problema</h1>
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="w-full max-w-lg">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Descrição do Problema
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows={4}
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Seu Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-[#006633] hover:bg-[#005522] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReportProblem;
