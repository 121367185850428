import React from "react";
import axios from 'axios';

export async function login(inputEmail: string, inputPassword: string) {
    try {
        const login = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/login/',
            {
                email: inputEmail,
                password: inputPassword,
            });
        if (login.status === 200) {
            return login;
        }
    } catch (error) {
        return []
    }
}