import axios from 'axios';

export async function indicatorsFiltred(id: number, is_active?: boolean) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/filtred/',
            {
                stage_id: id,
                is_active: is_active
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function indicatorsSimpleFiltred(id: number) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/filtred/simple/',
            {
                stage_id: id
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function indicatorsSimpleFiltredById(id: number) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/filtred/simple/',
            {
                id: id
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function indicatorsSimpleFiltredCacheById(id: number) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/filtred/cache/simple/',
            {
                id: id
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function indicatorsFiltredById(id: number) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/filtred/',
            {
                id: id
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function indicatorsFiltredCacheById(id: number) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/filtred/cache/',
            {
                id: id
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function editIndicator(idIndicator: number, inputRequirements: string, inputCod: string, inputIndicator: string, indicatorIsActive: boolean) {
    try {
        const indicatorsRequest = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/indicators/',
            {
                id: idIndicator,
                requirements: inputRequirements,
                cod: inputCod,
                indicators: inputIndicator,
                is_active: indicatorIsActive
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorsRequest.status === 200) {
            return indicatorsRequest
        }
    } catch (error) {
        return []
    }
}

export async function editCategoriesInIndicator(idIndicator: number, indicatorIsActive: boolean) {
    try {
        const categoriesInIndicator = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/indicators/',
            {
                id: idIndicator,
                is_active: indicatorIsActive
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (categoriesInIndicator.status === 200) {
            return categoriesInIndicator
        }
    } catch (error) {
        return []
    }
}