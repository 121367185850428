import React from "react";
import axios from 'axios';

export async function resetPassword(inputEmail: string) {
    try {
        const responseReset = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/reset-password/',
            {
                email: inputEmail
            });
        if (responseReset.status === 200) {
            return responseReset;
        }
    } catch (error) {
        return []
    }
}

export async function alterPassword(inputCurrentPassword: string, inputPassword: string) {
    try {
        const alterPassword = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user/',
            {
                old_password: inputCurrentPassword,
                new_password: inputPassword,
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (alterPassword.status === 200) {
            return alterPassword;
        }
    } catch (error) {
        return []
    }
}

export async function checkToken(id: string, token: string) {
    try {
        const checkResponse = await axios.get(process.env.REACT_APP_URL_API_AMZ+`api/auth-amz/check-token/${id}/${token}/`);
        if (checkResponse.status === 200) {
            return checkResponse;
        }
    } catch (error) {
        return []
    }
}

export async function completeResetPassword(id: string, token: string, inputPassword: string, inputConfPassword: string) {
    try {
        const resetCompletePassword = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/complete-reset-password/',
            {
                uidb64: id,
                token: token,
                password: inputPassword,
                conf_password: inputConfPassword
            });
        if (resetCompletePassword.status === 200) {
            return resetCompletePassword;
        }
    } catch (error) {
        return []
    }
}