import axios from 'axios';

export async function services() {
    try {
        const indicatorRequest = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/services/',
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function servicesSimple() {
    try {
        const indicatorRequest = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/services/simple/',
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data
        }
    } catch (error) {
        return []
    }
}