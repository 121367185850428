import React, { ChangeEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MessageRequest from '../components/CardMessage/MessageRequest/messageRequest';
import { completeResetPassword, checkToken } from '../api/resetPassword';
import { registerUserLogs } from '../api/userLogs';

export default function CompleteResetPasswordScreen() {
    const { id, token } = useParams();
    const [inputPassword, setinputPassword] = useState<string>('');
    const [inputConfPassword, setinputConfPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<string | null>(null);
    const [errorConfPassword, setErrorConfPassword] = useState<string | null>(null);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');

    const idString: string = id !== undefined ? id : "";
    const tokenString: string = token !== undefined ? token : "";

    useEffect(() => {
        const fetchData = async () => {
            const checkReponse: any = checkToken(idString, tokenString);

            if (checkReponse.status === 401) {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Token inválido.')
            }
        }
        fetchData();
    }, [idString, tokenString]);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
        setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
    };

    const handleInputPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputPassword(event.target.value);
        setErrorPassword(null);
    }

    const handleInputConfPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputConfPassword(event.target.value);
        setErrorConfPassword(null);
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputPassword.trim() === '') {
            setErrorPassword('Campo obrigatório.')
            return;
        } else {
            setErrorPassword('')
        }

        if (inputConfPassword.trim() === '') {
            setErrorConfPassword('Campo obrigatório.')
            return;
        } else if (inputConfPassword !== inputPassword) {
            setErrorConfPassword('Senhas não correspondem.')
            return;
        } else {
            setErrorConfPassword('')
        }

        const editPassword: any = await completeResetPassword(idString, tokenString, inputPassword, inputConfPassword);
        if (editPassword.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Senha alterada com sucesso.')
            registerUserLogs("Alterou sua senha.");
        } else {
            if (editPassword.status === 401) {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Token inválido.')
            } else {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Tente novamente mais tarde.')
            }
        }
    }

    const classNamePassword = errorPassword ? 'input-error' : 'input';
    const classNameConfPassword = errorConfPassword ? 'input-error' : 'input';

    return (
        <div>
            <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            <div className="flex items-center justify-center min-h-screen bg-white p-2">
                <div className="bg-green-700 p-8 rounded-lg shadow-md w-full sm:w-96">
                    <h2 className="text-2xl font-semibold text-white text-center mb-4">NOVA SENHA</h2>
                    <form>
                        <label className="block text-white">senha</label>
                        <input type={showPassword ? 'text' : 'password'} id='password' onChange={handleInputPassword} className={errorPassword && errorPassword !== '' ? "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none border-red-500" : "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:border-blue-500" } />
                        <span>{errorPassword}</span>
                        <label className="block text-white">confirmar senha</label>
                        <input type={showConfPassword ? 'text' : 'password'} id='conf-password' onChange={handleInputConfPassword} className={errorConfPassword && errorConfPassword !== '' ? "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none border-red-500" : "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:border-blue-500" } />
                        <span>{errorConfPassword}</span>
                        <div className="form-checkbox" id="form_checkbox">
                            <label className='text-white'>
                                <input type="checkbox" id="view_password" onChange={togglePasswordVisibility}  /> Mostrar senhas
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button className="bg-white text-green-700 px-4 py-2 rounded-md transition-colors duration-300 hover:bg-gray-300 hover:text-white focus:outline-none focus:bg-gray-300 focus:text-white border-50 border-green-700" onClick={handleSubmit}>enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
