import MessageRequest from "../CardMessage/MessageRequest/messageRequest";
import './modalRegisterStage.css'
import React, { useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import axios from 'axios';
import { categories, stages } from '../../api/categoriesStages'

type ModalRegisterStageProps = {
    activeModal: boolean, 
    titleModal: string, 
    idStage: number, 
    indicators: Array<any>,
    open: any
}

export default function ModalRegisterStage({activeModal, titleModal, idStage, indicators, open}: ModalRegisterStageProps) {
    const [active, setActive] = useState<boolean>(false);
    const [activeModalRegister, setActiveModalRegister] = useState<boolean>(false);
    const [dataCategories, setDataCategories] = useState<string>('');
    const [inputStage, setinputStage] = useState<string>('');
    const [errorStage, setErrorStage] = useState<string | null>(null);
    const [inputRequirements, setinputRequirements] = useState<string>('');
    const [errorRequirements, setErrorRequirements] = useState<string | null>(null);
    const [inputCod, setinputCod] = useState<string>('');
    const [errorCod, setErrorCod] = useState<string | null>(null);
    const [inputIndicator, setinputIndicator] = useState<string>('');
    const [errorIndicator, setErrorIndicator] = useState<string | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [errorCategories, setErrorCategories] = useState<string | null>(null);
    const [dataStages, setDataStages] = useState<number[]>([]);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');

    useEffect(() => {
        setActive(activeModal);
        setActiveModalRegister(activeModal);
    }, [activeModal]);

    const handleCheckboxChange = (categoryId: number) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const responseCategories = await categories();
            const responseStages = await stages();

            setDataCategories(responseCategories);
            setDataStages(responseStages);
        }

        fetchData();
    }, []);

    const handleInputStage = (event: any) => {
        setinputStage(event.target.value);
        setErrorStage(null);
    }

    const handleInputRequirements = (event: ChangeEvent<HTMLInputElement>) => {
        setinputRequirements(event.target.value);
        setErrorRequirements(null);
    }

    const handleInputCod = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCod(event.target.value);
        setErrorCod(null);
    }

    const handleInputIndicator = (event: ChangeEvent<HTMLInputElement>) => {
        setinputIndicator(event.target.value);
        setErrorIndicator(null);
    }

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(false);
        setActive(false);
        open(false);
    };

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputIndicator.trim() === '' && titleModal !== 'Nova Categoria') {
            setErrorIndicator('Campo obrigatório.')
            return;
        } else {
            setErrorIndicator('')
        }

        if (selectedCategories.length === 0 && titleModal !== 'Nova Categoria') {
            setErrorCategories('Campo obrigatório.')
            return;
        } else {
            setErrorCategories('')
        }

        if (titleModal === 'Novo Indicador') {
            axios.put(process.env.REACT_APP_URL_API_AMZ+'api/indicators/', {
                stage_id: idStage,
                requirements: inputRequirements,
                cod: inputCod,
                indicators: inputIndicator,
                categories: selectedCategories,
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then((response) => {
                setActiveModalRegister(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Cadatrado com sucesso.')
                setTimeout(() => {
                    setActiveModalRegister(false);
                }, 3000);
            })
            .catch((error) => {
                setActiveModalRegister(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            });
        } 

        if (titleModal === 'Nova Etapa') {
            axios.put(process.env.REACT_APP_URL_API_AMZ+'api/indicators/', {
                stage_id: parseInt(inputStage),
                requirements: inputRequirements,
                cod: inputCod,
                indicators: inputIndicator,
                categories: selectedCategories,
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then((response) => {
                setActiveModalRegister(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Cadatrado com sucesso.')
                setTimeout(() => {
                    setActiveModalRegister(false);
                }, 3000);
            })
            .catch((error) => {
                setActiveModalRegister(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            });
        } 
        
        if (titleModal === 'Nova Categoria') {
            let categoriesEdition = []
            for (let i=0; i < indicators.length; i++) {
                console.log(indicators[i].id)
                categoriesEdition.push(parseInt(indicators[i].id))
            }
            categoriesEdition.push(parseInt(inputStage))

            axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/stamps/', {
                id: idStage,
                categories: categoriesEdition
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then((response) => {
                setActiveModalRegister(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Cadatrado com sucesso.')
                setTimeout(() => {
                    setActiveModalRegister(false);
                }, 3000);
            })
            .catch((error) => {
                setActiveModalRegister(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            });
        } 
    }

    const classNameStage = errorStage ? 'input-register-error' : 'input-register';
    const classNameRequirements = errorRequirements ? 'input-register-error' : 'input-register';
    const classNameCod = errorCod ? 'input-register-error' : 'input-register';
    const classNameIndicator = errorIndicator ? 'input-register-error' : 'input-register';
    const classNameCategories = errorCategories ? 'input-register-error' : 'input-register';
    const [stage, setStage] = useState(1);

    const prevStage = () => {
        setStage(stage - 1);
    }

    const advanceStage = (event: any) => {
        event.preventDefault();

        if (stage === 1) {
            if (titleModal === 'Nova Etapa') {
                if (inputStage.trim() === '') {
                    setErrorStage('Campo obrigatório.')
                    return;
                } else {
                    setErrorStage('')
                }
            }
    
            if (inputRequirements.trim() === '') {
                setErrorRequirements('Campo obrigatório.')
                return;
            } else {
                setErrorRequirements('')
            }
    
            if (inputCod.trim() === '') {
                setErrorCod('Campo obrigatório.')
                return;
            } else {
                setErrorCod('')
            }
        }
        setStage(stage + 1);
    }
    
    return(
        <div className={active ? 'background-register-indicator' : 'modal-register-indicator-none'}>
            <div className='col-message'>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            </div>
            <div className={activeModalRegister ? 'modal-register-indicator' : 'modal-register-indicator-none'}>
                {titleModal !== 'Nova Categoria' && stage === 1 && (
                    <form className='form-register py-2'>
                        <div className='col-title-image-nav mb-4'>
                            <img
                                src="/amz.png"
                                alt="Imagem de cadastro"
                                className="register-image-nav"
                                style={{'marginLeft': '15px'}}
                            />
                            <h3> {titleModal} </h3>
                        </div>
                        {titleModal === 'Nova Etapa' ? 
                            (<div>
                                <div className='row-label'>
                                    <label className='label-register'>Etapa</label>
                                    <p className='required'>*</p>
                                </div>
                                <select className="select-register" value={inputStage} onChange={handleInputStage}>
                                    <option value="">Selecione...</option>
                                    {dataStages.map((stage: any) => (
                                        <option key={stage.id} value={stage.id}>
                                            {stage.stage}
                                        </option>
                                    ))}
                                </select>
                                <span>{errorStage}</span>
                            </div>) : 
                            (<div></div>)
                        }
                        <div className='row-label'>
                            <label className='label-register'>Requisito</label>
                            <p className='required'>*</p>
                        </div>
                        <input type="text" onChange={handleInputRequirements} className={classNameRequirements} value={inputRequirements}/>
                        <span>{errorRequirements}</span>
                        <div className='row-label'>
                            <label className='label-register'>Cod</label>
                            <p className='required'>*</p>
                        </div>
                        <input type="text" onChange={handleInputCod} className={classNameCod} value={inputCod}/>
                        <span>{errorCod}</span>
                        <div className='div-button-register-indicator'>
                            <button onClick={handleCancel}>cancelar</button>
                            <button onClick={advanceStage}>próximo</button>
                        </div>
                    </form>
                )}
                {titleModal !== 'Nova Categoria' && stage === 2 && (
                    <form className='form-register py-2'>
                        <button style={{'width': '50px', 'padding': '0'}} onClick={prevStage}>voltar</button>
                        <div className='row-label'>
                            <label className='label-register'>Indicador</label>
                            <p className='required'>*</p>
                        </div>
                        <input type="text" onChange={handleInputIndicator} className={classNameIndicator} value={inputIndicator}/>
                        <span>{errorIndicator}</span>
                        <div className='row-label'>
                            <label className='label-register'>Categorias</label>
                            <p className='required'>*</p>
                        </div>
                        <div className='col-checkbox'>
                            <ul>
                                {Array.isArray(dataCategories) ? (
                                    dataCategories.map((category) => (
                                        <li key={category.id} className='li-categories'>
                                        <label style={{"fontSize": '12px', 'display': 'flex', 'alignItems': 'center'}} className='label-check'>
                                            <input
                                                type="checkbox"
                                                checked={selectedCategories.includes(category.id)}
                                                onChange={() => handleCheckboxChange(category.id)}
                                                className="mr-2"
                                            />
                                            {category.categorie}
                                        </label>
                                        </li>
                                    ))
                                    ) : (
                                        <p>Erro: dataCategories não é uma array.</p>
                                )}
                            </ul>
                        </div>
                        <span>{errorCategories}</span>
                        <div className='buttons-register-stage'>
                            <button className='button-new-stage rounded' onClick={handleSubmit} type="button">
                                cadastrar
                            </button>
                            <button className='button-cancel-stage rounded' onClick={handleCancel}>
                                <u>cancelar</u>
                            </button>
                        </div>
                    </form>
                )}
                {titleModal === 'Nova Categoria' && (
                    <form className='form-register my-2'>
                        <div className='col-title-image-nav mb-4'>
                            <img
                                src="/amz.png"
                                alt="Imagem de cadastro"
                                className="register-image-nav"
                                style={{'marginLeft': '15px'}}
                            />
                            <h3> {titleModal} </h3>
                            <hr className='divider-modal' />
                        </div>
                        <div>
                            <div className='row-label'>
                                <label className='label-register'>Categorias</label>
                                <p className='required'>*</p>
                            </div>
                            <select className="select-register" value={inputStage} onChange={handleInputStage}>
                                <option value="">Selecione...</option>
                                {dataStages.map((stage: any) => (
                                    <option key={stage.id} value={stage.id}>
                                        {stage.stage}
                                    </option>
                                ))}
                            </select>
                            <span>{errorStage}</span>
                        </div>
                        <span>{errorCategories}</span>
                        <div className='buttons-register-stage'>
                            <button className='button-new-stage rounded' onClick={handleSubmit} type="button">
                                cadastrar
                            </button>
                            <button className='button-cancel-stage rounded' onClick={handleCancel}>
                                <u>cancelar</u>
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}