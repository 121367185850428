import React, { useEffect, useState } from 'react';
import ProfileEdit from './ProfileEdit';
import { useParams } from 'react-router-dom';
import { registerUserLogs } from '../api/userLogs';
import { profile } from '../api/profile';
import { editUser } from '../api/editUser';

interface User {
  id: number,
  name: string,
  email: string,
  cpf: string,
  office: string,
  company: string,
  cnpj: string,
  address_cep_address: string,
  endereco2: string,
  cep_address: string,
  cep_cep_address: string,
  num_cep_address: number,
  complement: string,
  neighborhood_cep_address: string,
  city_cep_address: string,
  uf_cep_address: string,
  country_cep_address: string,
  phone: string,
  is_active: boolean
}

const UsersProfile = ({userSelected}: {userSelected: User}) => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<User>(userSelected);

  const handleEditClick = async () => {
    setIsEditing(true);
    const response: any = await editUser(user.name, user.email, user.phone, user.cpf, user.company, user.cnpj, user.office, parseInt(user.cep_address), true);

    if (response.status === 200) {
      registerUserLogs("Editou usuário.");
    } 
  };

  const handleDeactiveUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const responseEditUser: any = await editUser(user.name, user.email, user.phone, user.cpf, user.company, user.cnpj, user.office, parseInt(user.cep_address), false);

      if (responseEditUser.status === 200) {
          registerUserLogs("Inativou usuário.");
      } 

      window.location.reload();
  }

  const handleActiveUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const responseEditUser: any = await editUser(user.name, user.email, user.phone, user.cpf, user.company, user.cnpj, user.office, user.id, true);

    if (responseEditUser.status === 200) {
        registerUserLogs("Inativou usuário.");
    } 

    window.location.reload();
}

  const handleSave = (updatedUser: User) => {
    setUser(updatedUser);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="mt-20 relative text-left p-4">
      {isEditing ? (
        <ProfileEdit user={user} onCancel={handleCancel} />
      ) : (
        <div className='flex flex-row justify-between'>
          <div className="flex flex-col items-start">
            <h1 className="text-2xl font-bold mb-4">Perfil</h1>
            <p className="mb-6">Detalhes do perfil de {user.name}.</p>
            <div className="border-transparent bg-white inline-block mb-4">
              <h2 className="text-lg font-semibold mb-2">Informações Pessoais</h2>
              <p><strong>Nome:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Tipo de Perfil:</strong> {user.office}</p>
            </div>
            <hr />
            <div className="border-transparent bg-white inline-block mb-4 mt-5">
              <h2 className="text-lg font-semibold mb-2">Informações da Empresa</h2>
              <p><strong>Empresa:</strong> {user.company}</p>
              <p><strong>Endereço:</strong> {user.address_cep_address}</p>
              <p><strong>Telefone:</strong> {user.phone}</p>
            </div>
            <hr />
          </div>
          <div className='flex flex-row justify-between gap-2 h-[50px]'>
            <button 
              className="top-4 right-4 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
              onClick={handleEditClick}
            >
              Editar
            </button>
            {user.is_active ? (
              <button 
                className="top-4 right-4 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
                onClick={handleDeactiveUser}
              >
                Inativar
              </button>
            ) : (
              <button 
                className="top-4 right-4 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
                onClick={handleActiveUser}
              >
                Ativar
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersProfile;
