import axios from 'axios';
import React, { MouseEventHandler, useState } from 'react';
import InputMask from 'react-input-mask';
import MessageRequest from '../components/CardMessage/MessageRequest/messageRequest';
import { registerUserLogs } from '../api/userLogs';

interface User {
  id: number,
  name: string,
  email: string,
  cpf: string,
  office: string,
  company: string,
  cnpj: string,
  address_cep_address: string,
  endereco2: string,
  cep_address: string,
  cep_cep_address: string,
  num_cep_address: number,
  complement: string,
  neighborhood_cep_address: string,
  city_cep_address: string,
  uf_cep_address: string,
  country_cep_address: string,
  phone: string,
}

interface Profile { 
  user: User, onCancel: MouseEventHandler<HTMLButtonElement> 
}

const ProfileEdit = ({ user, onCancel }: Profile) => {
  const [formData, setFormData] = useState({ ...user });
  const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
  const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
  const [messageRequest, setMessageRequest] = useState<string>('');
  const [errorName, setErrorName] = useState<string | null>(null);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPhone, setErrorPhone] = useState<string | null>(null);
  const [errorCpf, setErrorCpf] = useState<string | null>(null);
  const [errorCompany, setErrorCompany] = useState<string | null>(null);
  const [errorCnpj, setErrorCnpj] = useState<string | null>(null);
  const [errorOffice, setErrorOffice] = useState<string | null>(null);
  const [errorCep, setErrorCep] = useState<string | null>(null);
  const [errorAddress, setErrorAddress] = useState<string | null>(null);
  const [errorNum, setErrorNum] = useState<string | null>(null);
  const [errorComplement, setErrorComplement] = useState<string | null>(null);
  const [errorNeighborhood, setErrorNeighborhood] = useState<string | null>(null);
  const [errorCity, setErrorCity] = useState<string | null>(null);
  const [errorUf, setErrorUf] = useState<string | null>(null);
  const [errorCountry, setErrorCountry] = useState<string | null>(null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email: any) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.name.trim() === '') {
        setErrorName('Campo obrigatório.')
        return;
    } else {
        setErrorName('')
    }

    if (formData.email.trim() === '') {
        setErrorEmail('Campo obrigatório.')
        return;
    } else if(!validateEmail(formData.email)) {
        setErrorEmail('E-mail inválido.')
    } else {
        setErrorEmail('')
    }

    if (formData.phone.trim() === '') {
        setErrorPhone('Campo obrigatório.')
        return;
    } else {
        setErrorPhone('')
    }

    if (formData.cpf.trim() === '') {
        setErrorCpf('Campo obrigatório.')
        return;
    } else {
        setErrorCpf('')
    }

    if (formData.company.trim() === '') {
      setErrorCompany('Campo obrigatório.')
      return;
    } else {
        setErrorCompany('')
    }

    if (formData.cnpj.trim() === '') {
        setErrorCnpj('Campo obrigatório.')
        return;
    } else {
        setErrorCnpj('')
    }

    if (formData.office.trim() === '') {
        setErrorOffice('Campo obrigatório.')
        return;
    } else {
        setErrorOffice('')
    }

    if (formData.cep_cep_address.trim() === '') {
        setErrorCep('Campo obrigatório.')
        return;
    } else {
        setErrorCep('')
    }

    if (formData.address_cep_address.trim() === '') {
        setErrorAddress('Campo obrigatório.')
        return;
    } else {
        setErrorAddress('')
    }

    if (formData.neighborhood_cep_address.trim() === '') {
        setErrorNeighborhood('Campo obrigatório.')
        return;
    } else {
        setErrorNeighborhood('')
    }

    if (formData.city_cep_address.trim() === '') {
        setErrorCity('Campo obrigatório.')
        return;
    } else {
        setErrorCity('')
    }

    if (formData.uf_cep_address.trim() === '') {
        setErrorUf('Campo obrigatório.')
        return;
    } else {
        setErrorUf('')
    }

    if (formData.country_cep_address.trim() === '') {
        setErrorCountry('Campo obrigatório.')
        return;
    } else {
        setErrorCountry('')
    }

    axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/address/', {
        id: formData.cep_address,
        cep: formData.cep_cep_address,
        address: formData.address_cep_address,
        num: formData.num_cep_address,
        complement: formData.complement ? formData.complement : "null",
        neighborhood: formData.neighborhood_cep_address,
        city: formData.city_cep_address,
        uf: formData.uf_cep_address,
        country: formData.country_cep_address,
    },
    {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    })
    .then((response) => {
        setActiveMessageRequest(false);
        setActiveMessageRequest(true);
        setTitleMessageRequest('Sucesso:');
        setMessageRequest('Edição realizada com sucesso.')
    })
    .catch((error) => {
        setActiveMessageRequest(false);
        setActiveMessageRequest(true);
        setTitleMessageRequest('Erro:');
        setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
    });

    axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user/', {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        cpf: formData.cpf,
        company: formData.company,
        cnpj: formData.cnpj,
        office: formData.office,
        is_active: true
    },
    {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        }
    })
    .then((response) => {
        setActiveMessageRequest(false);
        setActiveMessageRequest(true);
        setTitleMessageRequest('Sucesso:');
        setMessageRequest('Edição realizada com sucesso.')
        registerUserLogs("Dados pessoais de usuário foram editados.");
    })
    .catch((error) => {
        setActiveMessageRequest(false);
        setActiveMessageRequest(true);
        setTitleMessageRequest('Erro:');
        setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
    });
  };

  return (
    <div className="p-4">
      <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
      <h2 className="text-xl font-bold mb-4">Editar Perfil</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Nome</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={errorName && errorName !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" } 
          />
          <span>{errorName}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={errorEmail && errorEmail !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorEmail}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">CPF</label>
          <InputMask
              mask="999.999.999-99"
              name="cpf"
              type="text"
              maskChar="_"
              onChange={handleChange}
              value={formData.cpf}
              className={errorCpf && errorCpf !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorCpf}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Tipo de Perfil</label>
          <input
            type="text"
            name="office"
            value={formData.office}
            onChange={handleChange}
            className={errorOffice && errorOffice !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorOffice}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Empresa</label>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className={errorCompany && errorCompany !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorCompany}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">CNPJ</label>
          <input
            type="text"
            name="cnpj"
            value={formData.cnpj}
            onChange={handleChange}
            className={errorCnpj && errorCnpj !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorCnpj}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Endereço 1</label>
          <input
            type="text"
            name="address_cep_address"
            value={formData.address_cep_address}
            onChange={handleChange}
            className={errorAddress && errorAddress !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorAddress}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Número</label>
          <input
            type="text"
            name="num"
            value={formData.num_cep_address}
            onChange={handleChange}
            className={errorNum && errorNum !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorNum}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Complemento</label>
          <input
            type="text"
            name="complement"
            value={formData.complement}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Cep</label>
          <input
            type="text"
            name="cep_cep_address"
            value={formData.cep_cep_address}
            onChange={handleChange}
            className={errorCep && errorCep !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorCep}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Cidade</label>
          <input
            type="text"
            name="city_cep_address"
            value={formData.city_cep_address}
            onChange={handleChange}
            className={errorCity && errorCity !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorCity}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">UF</label>
          <input
            type="text"
            name="uf_cep_address"
            value={formData.uf_cep_address}
            onChange={handleChange}
            className={errorUf && errorUf !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorUf}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">País</label>
          <input
            type="text"
            name="country_cep_address"
            value={formData.country_cep_address}
            onChange={handleChange}
            className={errorCountry && errorCountry !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorCountry}</span>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Telefone</label>
          <InputMask
              mask="(99) 99999-9999"
              name='phone'
              type='text'
              onChange={handleChange}
              value={formData.phone}
              className={errorPhone && errorPhone !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" }
          />
          <span>{errorPhone}</span>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-[#006633] text-white rounded hover:bg-[#005522]"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileEdit;
