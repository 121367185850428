import axios from 'axios';

export async function categorieFiltred(list: any) {
    try {
        const stageRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/categories/',
            {
                categories: list
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (stageRequest.status === 200) {
            return stageRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function categorieSimpleFiltred(list: any) {
    try {
        const stageRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/categories/simple/',
            {
                categories: list
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (stageRequest.status === 200) {
            return stageRequest.data
        }
    } catch (error) {
        return []
    }
}