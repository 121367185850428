import MessageRequest from "../CardMessage/MessageRequest/messageRequest";
import './modalEditStage.css'
import React, { ChangeEvent, useEffect, useState } from 'react';
import { categories, stages } from '../../api/categoriesStages'
import { editIndicator } from "../../api/indicators";
import { editCategoriesInIndicator } from "../../api/indicators";

type ModalEditStageProps = {
    activeModal: boolean, 
    indicator: string, 
    titleModal: string,
    open: any
}

export default function ModalEditStage({activeModal, indicator, titleModal, open}: ModalEditStageProps) {
    const [active, setActive] = useState<boolean>(false);
    const [activeModalEdit, setActiveModalEdit] = useState<boolean>(false);
    const [dataCategories, setDataCategories] = useState<string>('');
    const [errorStage, setErrorStage] = useState<string>('');
    const [inputRequirements, setinputRequirements] = useState<string>('');
    const [errorRequirements, setErrorRequirements] = useState<string>('');
    const [inputCod, setinputCod] = useState<string>('');
    const [errorCod, setErrorCod] = useState<string>('');
    const [inputIndicator, setinputIndicator] = useState<string>('');
    const [errorIndicator, setErrorIndicator] = useState<string>('');
    const [errorCategories, setErrorCategories] = useState<string>();
    const [indicatorIsActive, setIndicatorIsActive] = useState<string>('');
    const [idIndicator, setIdIndicator] = useState<number>(0);
    const [dataStages, setDataStages] = useState<any>([]);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');

    useEffect(() => {
        setActive(activeModal);
        setActiveModalEdit(activeModal);

        const fetchData = async () => {
            try {
                const indicatorRequest: any = indicator;
                setIdIndicator(indicatorRequest['id']);
                setinputRequirements(indicatorRequest['requirements']);
                setinputCod(indicatorRequest['cod']);
                setinputIndicator(indicatorRequest['indicators']);
                setIndicatorIsActive(`${indicatorRequest['is_active']}`);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        }

        fetchData();
    }, [activeModal, indicator]);

    useEffect(() => {
        const fetchData = async () => {
            const responseCategories = await categories();
            const responseStages = await stages();

            setDataCategories(responseCategories);
            setDataStages(responseStages);
        }

        fetchData();
    }, []);

    const handleInputRequirements = (event: ChangeEvent<HTMLInputElement>) => {
        setinputRequirements(event.target.value);
        setErrorRequirements('');
    }

    const handleInputCod = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCod(event.target.value);
        setErrorCod('');
    }

    const handleInputIndicator = (event: ChangeEvent<HTMLInputElement>) => {
        setinputIndicator(event.target.value);
        setErrorIndicator('');
    }

    const handleIndicatorIsActive = (event: any) => {
        setIndicatorIsActive(event.target.value);
    }

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalEdit(false);
        setActive(false);
        open(false);
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (titleModal !== 'Editar Categoria') {
            if (inputIndicator.trim() === '') {
                setErrorIndicator('Campo obrigatório.')
                return;
            } else {
                setErrorIndicator('')
            }
        }

        if (titleModal === 'Editar Indicador') {
            const indicatorActive: boolean = indicatorIsActive === "true" ? true : false;

            const requestEditIndicator:any = await editIndicator(idIndicator, inputRequirements, inputCod, inputIndicator, indicatorActive);
            
            if (requestEditIndicator.status === 200) {
                setActiveModalEdit(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Editado com sucesso.')
                setTimeout(() => {
                    setActiveModalEdit(false);
                    window.location.reload();
                }, 3000);
            } else {
                setActiveModalEdit(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Dado(s) inválido(s). Revise-os e tente novamente.')
            }
        } 

        if (titleModal === 'Editar Etapa') {
            const indicatorActive: boolean = indicatorIsActive === "true" ? true : false;
            const requestEditIndicator:any = await editIndicator(idIndicator, inputRequirements, inputCod, inputIndicator, indicatorActive);
            
            if (requestEditIndicator.status === 200) {
                setActiveModalEdit(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Editado com sucesso.')
                setTimeout(() => {
                    setActiveModalEdit(false);
                    window.location.reload();
                }, 3000);
            } else {
                setActiveModalEdit(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Dado(s) inválido(s). Revise-os e tente novamente.')
            }
        } 
        
        if (titleModal === 'Editar Categoria') {
            const indicatorActive: boolean = indicatorIsActive === "true" ? true : false;
            const requestEditCategoriesIndicator: any = await editCategoriesInIndicator(idIndicator, indicatorActive);
            
            if (requestEditCategoriesIndicator.status === 200) {
                setActiveModalEdit(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Editado com sucesso.')
                setTimeout(() => {
                    setActiveModalEdit(false);
                    window.location.reload();
                }, 3000);
            } else {
                setActiveModalEdit(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Dado(s) inválido(s). Revise-os e tente novamente.')
            }
        } 
    }

    const classNameStage = errorStage ? 'input-edit-error' : 'input-edit';
    const classNameRequirements = errorRequirements ? 'input-edit-error' : 'input-edit';
    const classNameCod = errorCod ? 'input-edit-error' : 'input-edit';
    const classNameIndicator = errorIndicator ? 'input-edit-error' : 'input-edit';
    const classNameCategories = errorCategories ? 'input-edit-error' : 'input-edit';
    const [stage, setStage] = useState(1);

    const prevStage = () => {
        setStage(stage - 1);
    }

    const advanceStage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (stage === 1) {
            if (inputRequirements.trim() === '') {
                setErrorRequirements('Campo obrigatório.')
                return;
            } else {
                setErrorRequirements('')
            }
    
            if (inputCod === null) {
                setErrorCod('Campo obrigatório.')
                return;
            } else {
                setErrorCod('')
            }
        }
        setStage(stage + 1);
    }
    
    return(
        <div className={active ? 'background-edit-indicator' : 'modal-edit-indicator-none'}>
            <div className='col-message'>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            </div>
            <div className={activeModalEdit ? 'modal-edit-indicator' : 'modal-edit-indicator-none'}>
                {titleModal !== 'Editar Categoria' && stage === 1 && (
                    <form className='form-edit py-2'>
                        <div className='col-title-image-nav'>
                            <img
                                src="/amz.png"
                                alt="Imagem de cadastro"
                                className="register-image-nav"
                                style={{'marginLeft': '15px'}}
                            />
                            <h3> {titleModal} </h3>
                        </div>
                        <div className='row-label'>
                            <label className='label-edit'>Requisito</label>
                            <p className='required'>*</p>
                        </div>
                        <input type="text" onChange={handleInputRequirements} className={classNameRequirements} value={inputRequirements}/>
                        <span>{errorRequirements}</span>
                        <div className='row-label'>
                            <label className='label-edit'>Cod</label>
                            <p className='required'>*</p>
                        </div>
                        <input type="text" onChange={handleInputCod} className={classNameCod} value={inputCod}/>
                        <span>{errorCod}</span>
                        <div className='div-button-edit-indicator'>
                            <button onClick={handleCancel}>cancelar</button>
                            <button onClick={advanceStage}>próximo</button>
                        </div>
                    </form>
                )}
                {titleModal !== 'Editar Categoria' && stage === 2 && (
                    <form className='form-edit py-2'>
                        <button style={{'width': '50px', 'padding': '0'}} onClick={prevStage}>voltar</button>
                        <div className='row-label'>
                            <label className='label-edit'>Indicador</label>
                            <p className='required'>*</p>
                        </div>
                        <input type="text" onChange={handleInputIndicator} className={classNameIndicator} value={inputIndicator}/>
                        <span>{errorIndicator}</span>
                        <div className='row-label'>
                            <label className='label-edit' style={{marginTop: 0}}>Ativo</label>
                            <p className='required'>*</p>
                        </div>
                        <select className="select-edit" value={indicatorIsActive.toString()} onChange={handleIndicatorIsActive} style={{ 'marginBottom': '20px' }}>
                            <option value="true">sim</option>
                            <option value="false">não</option>
                        </select>
                        <div className='buttons-edit-stage'>
                            <button className='button-new-stage' onClick={handleSubmit} type="button">
                                salvar
                            </button>
                            <button className='button-cancel-stage' onClick={handleCancel}>
                                <u>cancelar</u>
                            </button>
                        </div>
                    </form>
                )}
                {titleModal === 'Editar Categoria' && (
                    <form className='form-edit'>
                        <div className='col-title-image-nav'>
                            <img
                                src="/amz.png"
                                alt="Imagem de cadastro"
                                className="register-image-nav"
                                style={{'marginLeft': '15px'}}
                            />
                            <h3> {titleModal} </h3>
                            <hr className='divider-modal' />
                        </div>
                        <div className='row-label'>
                            <label className='label-edit'>Ativo</label>
                            <p className='required'>*</p>
                        </div>
                        <select className="select-edit-users" value={indicatorIsActive.toString()} onChange={handleIndicatorIsActive} style={{ 'marginBottom': '20px' }}>
                            <option value="true">sim</option>
                            <option value="false">não</option>
                        </select>
                        <div className='buttons-edit-stage'>
                            <button className='button-new-stage' onClick={handleSubmit} type="button">
                                salvar
                            </button>
                            <button className='button-cancel-stage' onClick={handleCancel}>
                                <u>cancelar</u>
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}