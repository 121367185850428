import React from "react";
import axios from 'axios';

export async function documentations(user_id: string, stage_id: string) {
    try {
        const reponseDocumentations = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user-documentation/',
            {
                user_id: parseInt(user_id),
                stage_id: parseInt(stage_id),
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (reponseDocumentations.status === 200) {
            return reponseDocumentations.data;
        }
    } catch (error) {
        return []
    }
}