import jsPDF from "jspdf";
import "./Relatory/relatory.css"
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar/navbar";
import { qrcodeRelatory } from "../api/spreadsheets";
import { dataCompany } from "../api/dataCompany";
import axios from "axios";
import Loading from "../components/Loading/loading";
import MessageRequest from "../components/CardMessage/MessageRequest/messageRequest";
import { registerUserLogs } from "../api/userLogs";
import { PDFDocument } from 'pdf-lib';

const Certificate = () => {
    const { id, stageId } = useParams();
    const [company, setCompany] = useState();
    const [generating, setGenerating] = useState(false);
    const [spreadsheets, setSpreadsheets] = useState([]);
    const [activeLoading, setActiveLoading] = useState(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [urlQrCode, setUrlQrCode] = useState('');

    const IMAGE_WIDTH = 800; 
    const IMAGE_HEIGHT = 580;

    useEffect(() => {
        const fetchData = async () => {
            const companyRequest = await dataCompany(String(id), parseInt(stageId!));
            setCompany(companyRequest);
        }

        fetchData()
    }, []);

    const handleConvertToPdf = async () => {
        setGenerating(true);
        setActiveLoading(true);
        const content: any = document.getElementById('content-relatory');
        const pdf = new jsPDF('landscape', 'px', [IMAGE_WIDTH, IMAGE_HEIGHT]);

        await new Promise((resolve, reject) => {
            pdf.html(content, {
                callback: () => {
                    resolve(undefined);
                },
                x: 10,
                y: 10,
                width: IMAGE_WIDTH,
                windowWidth: content.scrollWidth,
            });
        });

        const pdfBytes = pdf.output('arraybuffer');
        const pdfDoc = await PDFDocument.create();
        const generatedPdf = await PDFDocument.load(pdfBytes);
        const generatedPdfPage = await pdfDoc.copyPages(generatedPdf, generatedPdf.getPageIndices());
        generatedPdfPage.forEach(page => pdfDoc.addPage(page));

        const mergedPdfBytes = await pdfDoc.save({ 
            useObjectStreams: true, 
            updateFieldAppearances: false
        });

        const generateQrCode = (formData: FormData) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    if (urlQrCode === '') {
                        const qrCode = await qrcodeRelatory(formData);
                        setUrlQrCode(qrCode.link);
                    } else {
                        setUrlQrCode(urlQrCode);
                    }
                    resolve();
                } catch (error) {
                    reject(error); 
                }
            });
        };
        
        const formData = new FormData();
        formData.append('file', new Blob([mergedPdfBytes], { type: 'application/pdf' }), 'Certificado');
        
        await generateQrCode(formData);
        
        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Certificado';

        link.click();

        setGenerating(false);
        setActiveLoading(false);
        registerUserLogs("Baixou relatório.");
    }

    const handleCancelPdf = () => {
        window.location.href = "/relatorios"
    }

    const handleUserId = async (event: any) => {
        setActiveLoading(true)
        const companyDataRequest = await dataCompany(event.target.value);
        setSpreadsheets(companyDataRequest.spreadsheets[0] || []);   
        setActiveLoading(false)
    }

    useEffect(() => {
        setSpreadsheets(spreadsheets);
    }, [spreadsheets])

    const formatDate = () => {
        const today = new Date();
        return today.toLocaleDateString("pt-BR", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    };

    const formatDateIn5Years = () => {
        const today = new Date();
        today.setFullYear(today.getFullYear() + 5);
    
        return today.toLocaleDateString("pt-BR", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    };

    return (
        <div className="background-relatory" style={{ margin: 0, padding: 0 }}>
            <Navbar title="Sistema AMZ" user={true} buttonDownload={true} functionDownload={handleConvertToPdf} />
                <div className="mt-[110px] mb-12">
                    <div id="content-relatory" className="h-full bg-[#E3E3E3] m-0 p-0">
                        <div className="flex">
                            <img  src="/certification_amz.png" alt="certificado" />
                            <p className="absolute text-justify mt-[270px] ml-[120px] w-[900px] h-[150px] text-[24px] font-[500]">
                                O Instituto de Certificação Amazon, no uso de suas atribuições,
                                concede a empresa {company && company['user']['name']} 
                                ({company && company['user']['cnpj']}) a Certificação Ambiental AMZ, tendo em vista a
                                sua adequação aos requisitos exigidos. 
                            </p>
                            <div className="flex flex-row mt-[435px] ml-[120px] gap-2 absolute bg-white w-[600px] justify-between">
                                <h2 className="text-[24px] font-[600]">
                                    Válido desde:
                                </h2>
                                <p className="absolute text-[24px] font-[500] ml-[160px]">
                                    {formatDate()}
                                </p>
                            </div>
                            <div className="flex flex-row mt-[480px] ml-[120px] gap-2 absolute bg-white w-[600px] justify-between">
                                <h2 className="text-[24px] font-[600]">
                                    Data de Validade:
                                </h2>
                                <p className="absolute text-[24px] font-[500] ml-[215px]">
                                    {formatDateIn5Years()}
                                </p>
                            </div>
                            {urlQrCode === '' ? (
                                <div></div>
                            ) : (
                                <img className="flex absolute w-[102px] h-[102px] mt-[625px] ml-[70px]" src={`data:image/jpeg;base64,${urlQrCode}`} alt="code" />
                            )}
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Certificate;
