import axios from 'axios';

export async function filterProjects(id: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/filtred-project/', 
        {
            id: id,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data[0]
        }
    } catch (error) {
        return []
    }
}

export async function filterProjectsActives(is_active: boolean) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/filtred-project/', 
        {
            is_active: is_active,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function filterProjectsFinisheds(finished: boolean) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/filtred-project/', 
        {
            finished: finished,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function filterProjectsApproveds(approved: boolean) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/filtred-project/', 
        {
            approved: approved,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function projects() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/project/', 
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function filterSpreadsheets(projectId: number, stageId: number | null) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/filtred/', 
        {
            project_id: projectId,
            stage_id: stageId
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function filesSpreadsheet(userId: number, spreadsheetId: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/files/', 
        {
            user_id: userId,
            spreadsheet_id: spreadsheetId
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function removeFileSpreadsheet(id: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/remove-file/', 
        {
            id: id,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 204) {
            return response;
        }
    } catch (error) {
        return []
    }
}

export async function filesRelatorySpreadsheet(userId: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/files/relatory/', 
        {
            user_id: userId,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function qrcodeRelatory(forms: any) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/generator-qrcode/', forms)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function registerProject(inputName: string, inputStakeholder: string, selectedCategories: Array<any>, selectedStages: Array<any>) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/project/', 
        {
            name: inputName,
            stakeholder: inputStakeholder,
            categories: selectedCategories,
            stages: selectedStages,
            finished: false
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 201) {
            return response
        }
    } catch (error) {
        return false
    }
}

export async function editProject(id: number, inputName: string, inputCnpj: string, is_active: string) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/project/', 
        {
            id: id,
            name: inputName,
            cnpj: inputCnpj,
            is_active: is_active,
            finished: false,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 201) {
            return response
        }
    } catch (error) {
        return false
    }
}

export async function finishProject(id: number) {
    try {
        const response = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/project/finish/', 
        {
            id: id,
            name: true
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response
        }
    } catch (error) {
        return false
    }
}

export async function approveProject(id: number) {
    try {
        const response = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/project/approve/', 
        {
            id: id,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response
        }
    } catch (error) {
        return false
    }
}

export async function registerSpreadsheet(project_id: number, indicators_id: number, stage_id: number) {
    try {
        const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/', 
        {
            project_id: project_id,
            stage_id: stage_id,
            indicators_id: indicators_id,
            evidence: null,
            service: 1,
            action: null,
            cost: 1,
            time: 1,
            difficulty: 1,
            enviroment_risk: 1,
            worker_risk: 1,
            community_risk: 1,
            legal_risk: 1,
            observation: null,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 201) {
            return false
        }
    } catch (error) {
        return true
    }
}

export async function editSpreadsheet(id: number, project_id: number, indicators_id: number, evidence: string, service: string, action: string, cost: string, time: string, difficulty: string, enviroment_risk: string, worker_risk: string, community_risk: string, legal_risk: string, observation: string) {
    try {
        const response = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/', 
        {
            id: id,
            project_id: project_id,
            indicators_id: indicators_id,
            evidence: evidence,
            service: parseInt(service),
            action: action,
            cost: parseInt(cost),
            time: parseInt(time),
            difficulty: parseInt(difficulty),
            enviroment_risk: parseInt(enviroment_risk),
            worker_risk: parseInt(worker_risk),
            community_risk: parseInt(community_risk),
            legal_risk: parseInt(legal_risk),
            observation: observation
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })

        return response
    } catch (error) {
        return true
    }
}