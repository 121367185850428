import axios from 'axios';

export async function sendReport(email: string, textProblem: string) {
    try {
        const responseRegister = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user-report/',
            {
                destiny: email ? email : 'sistemaamz@gmail.com',
                message: textProblem,
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseRegister.status === 200) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}