import React from 'react';
import Header from '../components/Header'; 
import { useLocation } from 'react-router-dom';

const HomePage = () => {
  const location = useLocation();
  const hideSidebarAndHeader = location.pathname === '/' || location.pathname === '/login';

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex flex-col">
        {!hideSidebarAndHeader && <Header />}
        <div className={`flex-1 p-8 ${!hideSidebarAndHeader ? 'mt-16' : ''}`}>
          <div className="flex items-center">
            <h1 className="flex-1 text-3xl font-bold text-green-700">AMZ é inovação</h1>
            <h2 className="flex-1 text-lg font-semibold text-black italic">A única certificação no Brasil que possibilita a certificação por Etapas</h2>
          </div>
          <p className="mt-4 text-base text-black">
            O AMZ, Sistema de Certificação Amazon, se destaca como o pioneiro e exclusivo Instituto de certificação nascido na região amazônica. Ele emprega métodos e protocolos inovadores no contexto da certificação ambiental de sistemas, produtos e/ou produtos autênticos da Amazônia, abrangendo a vasta área da Amazônia Legal.
          </p>
          <p className="mt-4 text-base text-black">
            O processo de certificação de A a Z é bastante simples. O empreendimento segue um sistema de certificação por etapas, onde a empresa pode optar por certificar apenas as etapas nas quais tem confiança em estar em conformidade com os padrões da Certificação AMZ. Cada etapa certificada é auditada pelo AMZ para verificar a conformidade. Se tudo estiver correto, a empresa pode avançar para as próximas etapas da certificação. Simultaneamente, durante a certificação de uma etapa, a empresa pode se preparar para as próximas etapas. Ao completar todas as etapas com sucesso, a empresa se torna elegível para receber o Selo AMZ.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
