import React from 'react';

export default function Pagination({handlePrevPage, indexListPages, valueButton, valueButton1, valueButton2, functionButton1, functionButton2, pageFinal, functionButtonFinal, handleNextPage, maxIndex}: any) {
    return (
        <div className="pagination-spreadsheets">
            <button className="button-pagination-next justify-center" onClick={handlePrevPage} style={indexListPages > 0 ? {display: "flex"} : {display: "none"}}>
                {"<"}
            </button>
            <button className={valueButton === valueButton1 ? "selected-spreadsheet" : "button-pagination"} onClick={functionButton1}>
                {valueButton1+1}
            </button>
            <button className={valueButton === valueButton2 ? "selected-spreadsheet" : "button-pagination"} onClick={functionButton2} style={valueButton2 ? {display: "block"} : {display: "none"}}>
                {valueButton2+1}
            </button>
            <p style={{margin: 0, height: "30px", marginTop: "15px", marginRight: "10px", fontWeight: 800, color: "#006633"}}>...</p>
            <button className={valueButton === pageFinal ? "selected-spreadsheet" : "button-pagination"} onClick={functionButtonFinal}>
                {pageFinal + 1}
            </button>
            <button className="button-pagination-next justify-center" onClick={handleNextPage} style={indexListPages < (maxIndex) ? {display: "flex"} : {display: "none"}}>
                {">"}
            </button>
        </div>
    );
}