import React from "react";
import axios from 'axios';

export async function registerClient(formData: any) {
    try {
        const responseRegister = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user/',
            formData
        );
        if (responseRegister.status === 201) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}

export async function registerUser(formData: any) {
    try {
        const responseRegister = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/admin-amz/',
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseRegister.status === 201) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}