// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 998 !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1s;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 90px;
    background-color: #fff;
    box-shadow: 0 0 40px #919090;
    border-radius: 5px;
    padding: 15px;
    position: fixed;
    z-index: 999 !important;
}

.card-content-loading-none {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: auto;
    position: fixed;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 2s;
}

.card-content-loading-none .loading {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,wBAAwB;IACxB,wDAAwD;IACxD,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,4BAA4B;IAC5B,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,eAAe;IACf,wBAAwB;IACxB,wDAAwD;IACxD,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".card-loading {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 998 !important;\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 1s;\n}\n\n.loading {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 260px;\n    height: 90px;\n    background-color: #fff;\n    box-shadow: 0 0 40px #919090;\n    border-radius: 5px;\n    padding: 15px;\n    position: fixed;\n    z-index: 999 !important;\n}\n\n.card-content-loading-none {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: auto;\n    height: auto;\n    position: fixed;\n    transition-property: all;\n    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n    transition-duration: 2s;\n}\n\n.card-content-loading-none .loading {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
