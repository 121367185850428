import React, { useState, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { login } from '../api/login';
import { registerUserLogs } from '../api/userLogs';

const Login = () => {
  const [inputEmail, setinputEmail] = useState<string>('');
  const [inputPassword, setinputPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const profile = localStorage.getItem("profile");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputEmail = async (event: ChangeEvent<HTMLInputElement>) => {
    setinputEmail(event.target.value);
    setErrorEmail(null);
  }

  const handleInputPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setinputPassword(event.target.value);
    setErrorPassword(null);
  }

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputEmail.trim() === '') {
      setErrorEmail('Campo obrigatório.')
      return;
    } else {
      setErrorEmail('')
    }

    if (inputPassword.trim() === '') {
      setErrorPassword('Campo obrigatório.')
      return;
    } else {
      setErrorPassword('')
    }

    const responseLogin: any = await login(inputEmail, inputPassword);
    
    if (responseLogin.status === 200) {
        localStorage.setItem('access_token', responseLogin.data['access'])
        localStorage.setItem('refresh_token', responseLogin.data['refresh'])
        localStorage.setItem('profile', responseLogin.data['profile'])
        navigate('/home-page');
        registerUserLogs("Fez Login.");
    } else {
      setErrorPassword('E-mail e/ou senha inválidos.');
    }
  }
  
  return (
    <div className="flex items-center justify-center min-h-screen bg-white p-2">
      <div className="bg-green-700 p-8 rounded-lg shadow-md w-full sm:w-96">
        <h2 className="text-2xl font-semibold text-white text-center mb-4">Login</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="email" className="block text-white">Email</label>
            <input type="email" id="email" name="email" value={inputEmail} onChange={handleInputEmail} className={(errorEmail && errorEmail !== '') || (errorPassword && errorPassword !== '') ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } />
            {errorEmail && errorEmail !== '' && (
              <span>
                {errorEmail}
              </span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-white">Senha</label>
            <div className='flex'>
              <input type={showPassword ? 'text' : 'password'} id='password' onChange={handleInputPassword} value={inputPassword} className={errorPassword && errorPassword !== '' ? "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none border-red-500" : "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:border-blue-500" } />
              <a className='absolute cursor-pointer' style={{'marginLeft': '285px', 'marginTop': '12px'}} onClick={togglePasswordVisibility}>
                <img style={{width: '20px'}} src="olho_negro.svg" alt="ver" />
              </a>
            </div>
            {errorPassword && errorPassword !== '' && (
              <span>
                {errorPassword}
              </span>
            )}
          </div>
          <div className="flex justify-center">
            <button onClick={handleSubmit} className="bg-white text-green-700 px-4 py-2 rounded-md transition-colors duration-300 hover:bg-gray-300 hover:text-white focus:outline-none focus:bg-gray-300 focus:text-white border-50 border-green-700">Entrar</button>
          </div>
          <div className='flex flex-col py-4'>
            <Link to="/send-email" className="text-white hover:text-white-50 text-center font-normal mb-2 px-0 rounded w-full">Esqueci a senha</Link>
            {profile === 'cliente' ? (
              <Link to="/register" className="text-white hover:text-white-50 text-center font-normal px-0 rounded w-full">Não é cadastrado?</Link>
            ) : (
              <div></div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
