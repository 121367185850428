import './registerClient.css';
import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MessageCancel from '../../components/CardMessage/messageCancel';
import MessageRequest from '../../components/CardMessage/MessageRequest/messageRequest';
import Navbar from '../../components/Navbar/navbar';
import { country } from '../../components/ApiIbge/country';
import { uf } from '../../components/ApiIbge/uf';
import { city } from '../../components/ApiIbge/city';
import { registerUser, registerClient } from '../../api/registerUser';
import { registerAddress } from '../../api/address';
import FileUploadArea from '../../components/FileUploadArea/fileUploadArea';
import { registerUserLogs } from '../../api/userLogs';
import Drawer from '@mui/material/Drawer';

type RegisterClientProps = {
    func: string, 
    url: string
}

export default function RegisterClient({func, url}: RegisterClientProps) {
    const [stage, setStage] = useState<number>(1);
    const [inputName, setinputName] = useState<string>('');
    const [inputEmail, setinputEmail] = useState<string>('');
    const [inputPhone, setinputPhone] = useState<string>('');
    const [inputCpf, setinputCpf] = useState<string>('');
    const [inputCompany, setinputCompany] = useState<string>('');
    const [inputCnpj, setinputCnpj] = useState<string>('');
    const [inputOffice, setinputOffice] = useState<string>('');
    const [inputDescription, setinputDescription] = useState<string>('');
    const [inputCep, setinputCep] = useState<string>('');
    const [inputAddress, setinputAddress] = useState<string>('');
    const [inputNum, setinputNum] = useState<number | null>();
    const [inputComplement, setinputComplement] = useState<string>('');
    const [inputNeighborhood, setinputNeighborhood] = useState<string>('');
    const [inputCity, setinputCity] = useState<string>('');
    const [inputState, setinputState] = useState<string>('');
    const [inputUf, setinputUf] = useState<string>('');
    const [inputCountry, setinputCountry] = useState<string>('');
    const [inputIdCep, setinputIdCep] = useState<string>('');
    const [inputProfileUser, setinputProfileUser] = useState<string>('');
    const [inputPassword, setinputPassword] = useState<string>('');
    const [inputConfPassword, setinputConfPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
    const [errorName, setErrorName] = useState<string | null>(null);
    const [errorEmail, setErrorEmail] = useState<string | null>(null);
    const [errorPhone, setErrorPhone] = useState<string | null>(null);
    const [errorCpf, setErrorCpf] = useState<string | null>(null);
    const [errorCompany, setErrorCompany] = useState<string | null>(null);
    const [errorCnpj, setErrorCnpj] = useState<string | null>(null);
    const [errorOffice, setErrorOffice] = useState<string | null>(null);
    const [errorCep, setErrorCep] = useState<string | null>(null);
    const [errorAddress, setErrorAddress] = useState<string | null>(null);
    const [errorNum, setErrorNum] = useState<string | null>(null);
    const [errorComplement, setErrorComplement] = useState<string | null>(null);
    const [errorNeighborhood, setErrorNeighborhood] = useState<string | null>(null);
    const [errorCity, setErrorCity] = useState<string | null>(null);
    const [errorUf, setErrorUf] = useState<string | null>(null);
    const [errorCountry, setErrorCountry] = useState<string | null>(null);
    const [errorPassword, setErrorPassword] = useState<string | null>(null);
    const [errorConfPassword, setErrorConfPassword] = useState<string | null>(null);
    const [activeMessage, setActiveMessage] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');
    const [nameFile, setNameFile] = useState<string>('');
    const [fileUpload, setFileUpload] = useState<string | null>(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const handleFileSelect = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);
    };
    
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
        setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
    };

    const handleInputName = (event: ChangeEvent<HTMLInputElement>) => {
        setinputName(event.target.value);
        setErrorName(null);
    }

    const handleInputEmail = (event: any) => {
        setinputEmail(event.target.value);
        setErrorEmail(null);
    }

    const handleInputPhone = (event: ChangeEvent<HTMLInputElement>) => {
        setinputPhone(event.target.value);
        setErrorPhone(null);
    }

    const handleInputCpf = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCpf(event.target.value);
        setErrorCpf(null);
    }

    const handleInputCompany = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCompany(event.target.value);
        setErrorCompany(null);
    }

    const handleInputCnpj = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCnpj(event.target.value);
        setErrorCnpj(null);
    }

    const handleInputOffice = (event: ChangeEvent<HTMLInputElement>) => {
        setinputOffice(event.target.value);
        setErrorOffice(null);
    }
    
    const handleInputDescription = (event: any) => {
        setinputDescription(event.target.value);
    }

    const handleInputCep = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCep(event.target.value);
        setErrorCep(null);
    }

    const handleInputAddress = (event: ChangeEvent<HTMLInputElement>) => {
        setinputAddress(event.target.value);
        setErrorAddress(null);
    }

    const handleInputNum = (event: any) => {
        setinputNum(event.target.value);
        setErrorNum(null);
    }

    const handleInputComplement = (event: ChangeEvent<HTMLInputElement>) => {
        setinputComplement(event.target.value);
        setErrorComplement(null);
    }

    const handleInputNeighborhood = (event: ChangeEvent<HTMLInputElement>) => {
        setinputNeighborhood(event.target.value);
        setErrorNeighborhood(null);
    }

    const handleInputCity = (event: any) => {
        setinputCity(event.target.value);
        setErrorCity(null);
    }

    const handleInputUf = (event: any) => {
        setinputUf(event.target.value);
        setErrorUf(null);
    }

    const handleInputCountry = (event: any) => {
        setinputCountry(event.target.value);
        setErrorCountry(null);
    }

    const handleInputPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputPassword(event.target.value);
        setErrorPassword(null);
    }

    const handleInputConfPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputConfPassword(event.target.value);
        setErrorConfPassword(null);
    }

    const handleProfileUser = (event: any) => {
        setinputProfileUser(event.target.value);
    }

    const validateEmail = (email: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const prevStage = () => {
        setStage(stage - 1);
    }

    const advanceStage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (stage === 1) {
            if (inputName.trim() === '') {
                setErrorName('Campo obrigatório.')
                return;
            } else {
                setErrorName('')
            }
    
            if (inputEmail.trim() === '') {
                setErrorEmail('Campo obrigatório.')
                return;
            } else if(!validateEmail(inputEmail)) {
                setErrorEmail('E-mail inválido.')
            } else {
                setErrorEmail('')
            }
    
            if (inputPhone.trim() === '') {
                setErrorPhone('Campo obrigatório.')
                return;
            } else {
                setErrorPhone('')
            }
    
            if (inputCpf.trim() === '') {
                setErrorCpf('Campo obrigatório.')
                return;
            } else {
                setErrorCpf('')
            }
        }

        if (stage === 2) {
            if (inputCompany.trim() === '') {
                setErrorCompany('Campo obrigatório.')
                return;
            } else {
                setErrorCompany('')
            }

            if (inputCnpj.trim() === '') {
                setErrorCnpj('Campo obrigatório.')
                return;
            } else {
                setErrorCnpj('')
            }
    
            if (inputOffice.trim() === '') {
                setErrorOffice('Campo obrigatório.')
                return;
            } else {
                setErrorOffice('')
            }
        }

        if (stage === 3) {
            if (inputCep.trim() === '') {
                setErrorCep('Campo obrigatório.')
                return;
            } else {
                setErrorCep('')
            }
    
            if (inputAddress.trim() === '') {
                setErrorAddress('Campo obrigatório.')
                return;
            } else {
                setErrorAddress('')
            }

            if (inputNeighborhood.trim() === '') {
                setErrorNeighborhood('Campo obrigatório.')
                return;
            } else {
                setErrorNeighborhood('')
            }
    
            if (inputCity.trim() === '') {
                setErrorCity('Campo obrigatório.')
                return;
            } else {
                setErrorCity('')
            }

            if (inputUf.trim() === '') {
                setErrorUf('Campo obrigatório.')
                return;
            } else {
                setErrorUf('')
            }
    
            if (inputCountry.trim() === '') {
                setErrorCountry('Campo obrigatório.')
                return;
            } else {
                setErrorCountry('')
            }
        }

        setStage(stage + 1);
    }

    const clearInputsStage = (event: any) => {
        event.preventDefault();

        if (stage === 1) {
            setinputName('');
            setinputEmail('');
            setinputPhone('');
            setinputCpf('');
        } else if (stage === 2) {
            setinputCompany('');
            setinputCnpj('');
            setinputOffice('');
        } else if (stage === 3) {
            setinputCep('');
            setinputAddress('');
            setinputComplement('');
            setinputNeighborhood('');
            setinputCity('');
            setinputUf('');
            setinputCountry('');
        } else if (stage === 4) {
            setinputPassword('');
            setinputConfPassword('');
        } 
    }

    const handleRegisterAddress = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputCep.trim() === '') {
            setErrorCep('Campo obrigatório.')
            return;
        } else {
            setErrorCep('')
        }

        if (inputAddress.trim() === '') {
            setErrorAddress('Campo obrigatório.')
            return;
        } else {
            setErrorAddress('')
        }

        if (inputNeighborhood.trim() === '') {
            setErrorNeighborhood('Campo obrigatório.')
            return;
        } else {
            setErrorNeighborhood('')
        }

        if (inputCity.trim() === '') {
            setErrorCity('Campo obrigatório.')
            return;
        } else {
            setErrorCity('')
        }

        if (inputUf.trim() === '') {
            setErrorUf('Campo obrigatório.')
            return;
        } else {
            setErrorUf('')
        }

        if (inputCountry.trim() === '') {
            setErrorCountry('Campo obrigatório.')
            return;
        } else {
            setErrorCountry('')
        }

        const numAddress = inputNum ? inputNum : null;

        const responseRegisterAddress: any = await registerAddress(inputCep, inputAddress, numAddress, inputComplement, inputNeighborhood, inputCity, inputUf, inputCountry);
        
        if (responseRegisterAddress.status === 201) {
            setinputIdCep(responseRegisterAddress.data['id_cep'])
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Endereço salvo com sucesso.');
        } 
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputPassword.trim() === '') {
            setErrorPassword('Campo obrigatório.')
            return;
        } else if (inputPassword.length < 8) {
            setErrorPassword('Senha contém menos de 8 caracteres.')  
        } else {
            setErrorPassword('')
        }

        if (inputConfPassword.trim() === '') {
            setErrorPassword('Campo obrigatório.')
            return;
        } else if (inputConfPassword !== inputPassword) {
            setErrorConfPassword('Senhas não correspondem.')
            return;
        } else {
            setErrorConfPassword('')
        }

        if (inputIdCep !== "") {
            setActiveMessageRequest(false);
            if (func === 'admin') {
                const formData = new FormData();
                formData.append('name', inputName);
                formData.append('email', inputEmail);
                formData.append('phone', inputPhone);
                formData.append('cpf', inputCpf);
                formData.append('company', inputCompany);
                formData.append('cnpj', inputCnpj);
                formData.append('description_company', inputDescription);
                if (fileUpload !== null) {
                    formData.append('image', fileUpload);
                }
                formData.append('office', inputOffice);
                formData.append('cep_address', inputIdCep);
                formData.append('groups', inputProfileUser);
                formData.append('password', inputPassword);

                const responseRegisterUser: any = await registerUser(formData);
                
                if (responseRegisterUser.status === 201) {
                    setActiveMessageRequest(true);
                    setTitleMessageRequest('Sucesso:');
                    setMessageRequest('Cadastrado com sucesso.');
                    registerUserLogs("Novo usuário foi cadastrado.");
                    setTimeout(() => {
                        window.location.href = '/users'
                    }, 3000)
                } else {
                    setActiveMessageRequest(true);
                    setTitleMessageRequest('Erro:');
                    setMessageRequest('Falha na requisição. Por favor, verifique seus dados.');
                }
            } else {
                try {
                    const formData = new FormData();
                    formData.append('name', inputName);
                    formData.append('email', inputEmail);
                    formData.append('phone', inputPhone);
                    formData.append('cpf', inputCpf);
                    formData.append('company', inputCompany);
                    formData.append('cnpj', inputCnpj);
                    formData.append('description_company', inputDescription);
                    if (fileUpload !== null) {
                        formData.append('image', fileUpload);
                    }
                    formData.append('office', inputOffice);
                    formData.append('cep_address', inputIdCep);
                    formData.append('password', inputPassword);
                    const responseRegisterClient: any = await registerClient(formData);
                    
                    if (responseRegisterClient.status === 201) {
                        setActiveMessageRequest(true);
                        setTitleMessageRequest('Sucesso:');
                        setMessageRequest('Cadastrado com sucesso.');
                        setTimeout(() => {
                            window.location.href = '/login'
                        }, 3000)
                    }
                } catch(e: any) {
                    if (e.response.data['erro'] === "{'email': [ErrorDetail(string='user com este email já existe.', code='unique')]}") {
                        setActiveMessageRequest(true);
                        setTitleMessageRequest('Erro:');
                        setMessageRequest('Este email já está cadastrado em nosso sistema. Por favor, tente com outro.');
                    } else {
                        setActiveMessageRequest(true);
                        setTitleMessageRequest('Erro:');
                        setMessageRequest('Falha na requisição. Por favor, verifique seus dados.');
                    }
                }
            }
        } 
    }

    const classNameName = errorName ? 'input-register-1-error' : 'input-register-1';
    const classNameEmail = errorEmail ? 'input-register-1-error' : 'input-register-1';
    const classNamePhone = errorPhone ? 'input-register-1-error' : 'input-register-1';
    const classNameCpf = errorCpf ? 'input-register-1-error' : 'input-register-1';
    const classNameCompany = errorCompany ? 'input-register-1-error' : 'input-register-1';
    const classNameCnpj = errorCnpj ? 'input-register-1-error' : 'input-register-1';
    const classNameOffice = errorOffice ? 'input-register-1-error' : 'input-register-1';
    const classNameCep = errorCep ? 'input-register-1-error' : 'input-register-1';
    const classNameAddress = errorAddress ? 'input-register-1-error' : 'input-register-1';
    const classNameNum = errorNum ? 'input-register-1-error' : 'input-register-1';
    const classNameComplement = errorComplement ? 'input-register-1-error' : 'input-register-1';
    const classNameNeighborhood = errorNeighborhood ? 'input-register-1-error' : 'input-register-1';
    const classNameCity = errorCity ? 'input-register-1-error' : 'input-register-1';
    const classNameUf = errorUf ? 'input-register-1-error' : 'input-register-1';
    const classNameCountry = errorCountry ? 'input-register-1-error' : 'input-register-1';
    const classNamePassword = errorPassword ? 'input-register-1-error' : 'input-register-1';
    const classNameConfPassword = errorConfPassword ? 'input-register-1-error' : 'input-register-1';

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveMessage(!activeMessage);
    }

    const handleStage1 = () => {
        setStage(1)
    }

    const handleStage2 = () => {
        setStage(2)
    }

    const handleStage3 = () => {
        setStage(3)
    }

    const handleStage4 = () => {
        setStage(4)
    }

    const [dataCountry, setDataCountry] = useState([]);
    const [dataUf, setDataUf] = useState([]);
    const [dataCity, setDataCity] = useState([]);

    useEffect(() => {
        const countryData = async () => {
            try {
                const countryRequest = await country();
                setDataCountry(countryRequest);
            } catch(e) {
                return [];
            }
        }
        countryData();
    }, []);

    useEffect(() => {
        const ufData = async () => {
            try {
                const ufRequest = await uf();
                setDataUf(ufRequest);
            } catch(e) {
                return [];
            }
        }
        ufData();
    }, []);

    useEffect(() => {
        const cityData = async () => {
            try {
                const cityRequest = await city(inputUf);
                setDataCity(cityRequest);
            } catch(e) {
                return [];
            }
        }
        
        if (inputUf != '') {
            cityData();
        }
    }, [inputUf]);

    return (
        <div className='register-col'>
            <Navbar title="Sistema AMZ" user={false} menu={toggleDrawer(true)} />
            <section>
                <div className='container-sidebar-register'>
                    <div className="sidebar-register">
                        <div className="menu_stages">
                            <a className={stage === 1 ? 'selected' : ''} onClick={handleStage1}>Dados Pessoais</a>
                            <a className={stage === 2 ? 'selected' : ''} onClick={handleStage2}>Empresa</a>
                            <a className={stage === 3 ? 'selected' : ''} onClick={handleStage3}>Endereço</a>
                            <a className={stage === 4 ? 'selected' : ''} onClick={handleStage4}>Senha</a>
                        </div>
                        <div className='col-end-sidebar'>
                            <button className='button-register' onClick={handleCancel}>CANCELAR</button>
                            <p className='p-npca'>
                                © NPCA 
                            </p>
                        </div>
                    </div>
                </div>
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-register">
                        <div className="menu_stages">
                            <a className={stage === 1 ? 'selected' : ''} onClick={handleStage1}>Dados Pessoais</a>
                            <a className={stage === 2 ? 'selected' : ''} onClick={handleStage2}>Empresa</a>
                            <a className={stage === 3 ? 'selected' : ''} onClick={handleStage3}>Endereço</a>
                            <a className={stage === 4 ? 'selected' : ''} onClick={handleStage4}>Senha</a>
                        </div>
                        <div className='col-end-sidebar'>
                            <button className='button-register' onClick={handleCancel}>CANCELAR</button>
                            <p className='p-npca'>
                                © NPCA 
                            </p>
                        </div>
                    </div>
                </Drawer>
                <MessageCancel activeProps={activeMessage} reloadProps={reloadMessage} url="/users" />
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                <section className='section-register'>
                    <div className="register-box">
                        <div className='row-1'>
                            <div className='col-title-sub-title'>
                                <div className='col-title-image'>
                                    <img
                                        src="/amz.png"
                                        alt="Imagem de cadastro"
                                        className="register-image"
                                    />
                                    <h2>CADASTRO</h2>
                                </div>
                                <p className='p-message'>
                                    *Os campos sinalizados em <p className='p-message-red'>VERMELHO</p> são obrigatórios.
                                </p>
                            </div>
                            <button className='button-register-clear px-2 button-clear' onClick={clearInputsStage}>limpar tudo</button>
                        </div>
                        {stage === 1 && (
                            <form className='form-register'>
                                <div className='row-childs'>
                                    <div className='col-childs1'>
                                        <div className='row-label'>
                                            <label className='label-register'>Nome Completo</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="text" onChange={handleInputName} className={errorName && errorName !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputName}/>
                                        <span>{errorName}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>E-mail</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="email" onChange={handleInputEmail} className={errorEmail && errorEmail !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputEmail}/>
                                        <span>{errorEmail}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Telefone</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            onChange={handleInputPhone}
                                            value={inputPhone}
                                            className={errorPhone && errorPhone !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" }
                                        />
                                        <span>{errorPhone}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>CPF</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <InputMask
                                            mask="999.999.999-99"
                                            maskChar="_"
                                            onChange={handleInputCpf}
                                            value={inputCpf}
                                            className={errorCpf && errorCpf !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" }
                                        />
                                        <span>{errorCpf}</span>
                                        {func === 'admin' ?
                                            (<div>
                                                <div className='row-label'>
                                                    <label className='label-register'>perfil</label>
                                                    <p className='required'>*</p>
                                                </div>
                                                <select className={"w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputProfileUser} onChange={handleProfileUser}>
                                                    <option value="">Selecione...</option>
                                                    <option value="administrador">Administrador</option>
                                                    <option value="auditor">Auditor</option>
                                                    <option value="camara">Camara</option>
                                                    <option value="cliente">Cliente</option>
                                                </select>
                                            </div>) :
                                            <div>{}</div>
                                        }
                                    </div>
                                </div>
                                <div className='div-button-register'>  
                                    <button className='button-register' onClick={advanceStage}>PRÓXIMO</button>
                                </div>
                            </form>
                        )}
                        {stage === 2 && (
                            <form className='form-register'>
                                <div className='row-childs'>
                                    <div className='col-childs1'>
                                        <div className='row-label'>
                                            <label className='label-register'>Empresa</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="text" onChange={handleInputCompany} className={errorCompany && errorCompany !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputCompany}/>
                                        <span>{errorCompany}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>CNPJ</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <InputMask
                                            mask="99.999.999/9999-99"
                                            maskChar="_"
                                            onChange={handleInputCnpj}
                                            value={inputCnpj}
                                            className={errorCnpj && errorCnpj !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" }
                                        />
                                        <span>{errorCnpj}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Cargo</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="text" onChange={handleInputOffice} className={errorOffice && errorOffice !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputOffice}/>
                                        <span>{errorOffice}</span>
                                        {(inputProfileUser === 'cliente' || func === 'client') && (<div className='row-label'>
                                            <label className='label-register'>Quem somos</label> 
                                            <p className='required'>*</p>
                                        </div>)}
                                        {(inputProfileUser === 'cliente' || func === 'client') &&  (<textarea className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500" onChange={handleInputDescription} value={inputDescription} cols={50} rows={8} style={{"resize": "none"}} />)}
                                        {(inputProfileUser === 'cliente' || func === 'client') && (<div className='row-label' style={{ marginTop: "15px" }}>
                                            <label className='label-register'>Logo da Empresa</label> 
                                            <p className='required'>*</p>
                                        </div>)}
                                        {(inputProfileUser === 'cliente' || func === 'client') && <FileUploadArea onFileSelect={handleFileSelect} nameFile={nameFile} />}
                                    </div>
                                        
                                    </div>
                                    <div className='div-button-register'>
                                        <div className='div-two-button'>
                                            <button className='button-register' onClick={prevStage}>ANTERIOR</button>
                                            <button className='button-register' onClick={advanceStage}>PRÓXIMO</button>
                                        </div>
                                    </div>
                            </form>
                        )}
                        {stage === 3 && (
                            <form className='form-register'>
                                <div className='row-childs'>
                                    <div className='col-childs1'>
                                        <div className='row-label'>
                                            <label className='label-register'>CEP</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="text" onChange={handleInputCep} className={errorCep && errorCep !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" }  value={inputCep}/>
                                        <span>{errorCep}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>País</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <select className={"w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputCountry} onChange={handleInputCountry}>
                                            <option value="">Selecione...</option>
                                            {dataCountry.map((country: any) => (
                                                <option key={country.id.M49} value={country.nome}>
                                                    {country.nome}
                                                </option>
                                            ))}
                                        </select>
                                        <span>{errorCountry}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Uf</label>
                                            <p className='required'>*</p>
                                        </div>
                                        {inputCountry !== "Brasil" ?
                                            (<input type="text" onChange={handleInputUf} className={errorUf && errorUf !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputUf}/>) :
                                            (<select className={"w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputUf} onChange={handleInputUf}>
                                                <option value="">Selecione...</option>
                                                {dataUf.map((uf: any) => (
                                                    <option key={uf.id} value={uf.sigla}>
                                                        {uf.sigla}
                                                    </option>
                                                ))}
                                            </select>)
                                        }
                                        <span>{errorUf}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Cidade</label>
                                            <p className='required'>*</p>
                                        </div>
                                        {inputCountry !== "Brasil" ?
                                            (<input type="text" onChange={handleInputCity} className={errorCity && errorCity !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputCity}/>) :
                                            (<select className={"w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputCity} onChange={handleInputCity}>
                                            <option value="">Selecione...</option>
                                                {dataCity.map((city: any) => (
                                                    <option key={city.id} value={city.nome}>
                                                        {city.nome}
                                                    </option>
                                                ))}
                                            </select>)
                                        }
                                        <span>{errorCity}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Bairro</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="text" onChange={handleInputNeighborhood} value={inputNeighborhood} className={errorNeighborhood && errorNeighborhood !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } />
                                        <span>{errorNeighborhood}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Endereço</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="text" onChange={handleInputAddress} value={inputAddress} className={errorAddress && errorAddress !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" }/>
                                        <span>{errorAddress}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Número</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type="number" onChange={handleInputNum} className={errorNum && errorNum !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputNum || undefined} />
                                        <span>{errorNum}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Complemento</label>
                                        </div>
                                        <input type="text" onChange={handleInputComplement} className={errorComplement && errorComplement !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputComplement}/>
                                        <span>{errorComplement}</span>
                                    </div>
                                </div>
                                <div className='div-button-register'>
                                    <div className='div-two-button'>
                                        <button className='button-register' onClick={prevStage}>ANTERIOR</button>
                                        {!inputIdCep && <button className='button-register' onClick={handleRegisterAddress}>SALVAR</button>}
                                        {inputIdCep && <button className='button-register' onClick={advanceStage}>PRÓXIMO</button>}
                                    </div>
                                </div>
                            </form>
                        )}
                        {stage === 4 && (
                            <form className='form-register'>
                                <div className='row-childs'>
                                    <div className='col-childs1'>
                                        <div className='row-label'>
                                            <label className='label-register'>Senha</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type={showPassword ? 'text' : 'password'} id='password' onChange={handleInputPassword} className={errorPassword && errorPassword !== '' ? "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none border-red-500" : "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputPassword} />
                                        <span>{errorPassword}</span>
                                        <div className='row-label'>
                                            <label className='label-register'>Confirmar Senha</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <input type={showConfPassword ? 'text' : 'password'} id='conf-password' onChange={handleInputConfPassword} className={errorConfPassword && errorConfPassword !== '' ? "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none border-red-500" : "w-full pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:border-blue-500" } value={inputConfPassword} />
                                        <span>{errorConfPassword}</span>
                                        <div className="form-checkbox" id="form_checkbox">
                                            <label className='label-register'>
                                                <input type="checkbox" id="view_password" onChange={togglePasswordVisibility}  /> Mostrar senhas
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='div-button-register'>
                                    <div className='div-two-button'>
                                        <button className='button-register' onClick={prevStage}>ANTERIOR</button>
                                        <button className='button-register' onClick={handleSubmit}>CADASTRAR</button>
                                    </div>
                                </div>
                            </form>
                        )} 
                    </div>
                </section>
            </section>
        </div>
    )
}