import React from 'react';

const reports = () => {
  return (
    <div className="mt-20 text-center">
      <h1 className="text-2xl font-bold mb-4">Painel de Configurações</h1>
      <div className="flex flex-wrap justify-center gap-4">
        <a href="/profile">
          <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
            Perfil
          </button>
        </a>
        <a href="/report-problem">
          <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
            Relatar Problema
          </button>
        </a>
      </div>
    </div>
  );
};

export default reports;
