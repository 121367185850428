// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-upload {
    background-color: #006633;
    color: #fff;
    width: 100px;
    padding: 0 15px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileUploadButton/fileUploadButton.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".button-upload {\r\n    background-color: #006633;\r\n    color: #fff;\r\n    width: 100px;\r\n    padding: 0 15px;\r\n    font-weight: 500;\r\n    margin: 0;\r\n    margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
