import React from 'react';

const ReportsFinal = () => {
  const users = [
    { id: 1, title: 'Título 1', name: 'cliente 1', date: '00/00/0000' , status: 'Finalizada' },
    { id: 2, title: 'Título 2', name: 'cliente 2', date: '00/00/0000' , status: 'Finalizada' },
    { id: 3, title: 'Título 3', name: 'cliente 3', date: '00/00/0000' , status: 'Finalizada' },
    
  ];

  const handleViewProfile = (id: number) => {
    console.log(`Visualizar perfil do usuário com ID: ${id}`);
    // lógica para visualizar o perfil do usuário
  };

  return (
    <div className="mt-20 p-4 md:p-8">
      <h1 className="text-2xl font-bold mb-4 text-center">Relatórios Finalizados</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-300">Título</th>
              <th className="py-2 px-4 border-b border-gray-300">Cliente</th>
              <th className="py-2 px-4 border-b border-gray-300">Data</th>
              <th className="py-2 px-4 border-b border-gray-300">Status</th>
              <th className="py-2 px-4 border-b border-gray-300">Ações</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="text-center">
                <td className="py-2 px-4 border-b border-gray-300">{user.title}</td>
                <td className="py-2 px-4 border-b border-gray-300">{user.name}</td>
                <td className="py-2 px-4 border-b border-gray-300">{user.date}</td>
                <td className="py-2 px-4 border-b border-gray-300">{user.status}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button 
                    className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                    onClick={() => handleViewProfile(user.id)}
                  >
                    Visualizar
                  </button>
                  <button 
                    className="ml-5 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                    onClick={() => handleViewProfile(user.id)}
                  >
                    Baixar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportsFinal;
