import axios from 'axios';

export async function city(UF: string) {
    try {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}