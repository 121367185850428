import axios from 'axios';

export async function Simulation() {
    try {
        const simulationRequest = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/simulation/',
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (simulationRequest.status === 200) {
            return simulationRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function filtredSimulation(stage_id: number | null) {
    try {
        const simulationRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/filtred-simulation/',
            {
                stage_id: stage_id
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (simulationRequest.status === 200) {
            return simulationRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function RegisterSimulation(stage_id: number, indicators_id: number) {
    try {
        const simulationRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/simulation/',
            {
                stage_id: stage_id,
                indicators_id: indicators_id,
                response_user: false,
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (simulationRequest.status === 200) {
            return simulationRequest.data
        }
    } catch (error) {
        return []
    }
}

export async function EditSimulation(id: number, response_user: boolean) {
    try {
        const simulationRequest = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/simulation/',
            {
                id: id,
                response_user: response_user
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (simulationRequest.status === 200) {
            return simulationRequest
        }
    } catch (error) {
        return []
    }
}