import axios from 'axios';

export async function stages() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/stages/',
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function stagesSimple() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/stages/simple/',
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function categories() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/categories/',
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}

export async function categoriesSimple() {
    try {
        const response = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/categories/simple/',
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        return []
    }
}