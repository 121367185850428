import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export async function profile(url: string) {

    try {
        const response: any = await axios.get(process.env.REACT_APP_URL_API_AMZ+`api/auth-amz/${url}/`, 
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })

        if (response.status === 200) {
            if (url !== 'admin-amz') {
                localStorage.setItem('id', response.data['cliente']['id'])
            }
            return response.data;
        } else {
            if (localStorage.getItem('refresh_token')) {
                const refresh: any = axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/refresh/', {
                    refresh: localStorage.getItem('refresh_token')
                })
    
                if (refresh.status === 200) {
                    localStorage.setItem('access_token', refresh.data['access']);
                    window.location.reload();
                } else {
                    localStorage.clear();
                    window.location.href = "/";       
                }
            } 
        }
    } catch (error) {
        if (localStorage.getItem('refresh_token')) {
            const refresh: any = axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/refresh/', {
                refresh: localStorage.getItem('refresh_token')
            })

            if (refresh.status === 200) {
                localStorage.setItem('access_token', refresh.data['access']);
                window.location.reload();
            } else {
                localStorage.clear();
                window.location.href = "/";       
            }
        } 
    }
}