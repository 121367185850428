import './loading.css';
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';

type LoadingProps = {activeProps: boolean};

export default function Loading({activeProps}: LoadingProps) {
    const [active, setActive] = useState(activeProps);

    useEffect(() => {
        setActive(activeProps)
    }, [activeProps]);

    return (
        <div className={active === true ? 'card-loading' : 'card-content-loading-none'}>
            <div className='loading'>
                <CircularProgress sx={{ color: "#006633" }} />
                <h4 style={{'margin': '10px 0 0 0', color: "#006633"}}>Carregando...</h4>
            </div>
        </div>
    );
}