import React, {useEffect, useState, ChangeEvent} from "react";
import './registerSpreadsheets.css'
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/navbar";
import Loading from "../../components/Loading/loading";
import MessageRequest from "../../components/CardMessage/MessageRequest/messageRequest";
import ColProfile from "../../components/ColProfile/colProfile";
import { categorieSimpleFiltred } from "../../api/categorieFiltred";
import { filtredSimulation, RegisterSimulation, EditSimulation } from "../../api/simulation";
import { StageCategoriesSimple } from "../../api/stageCategories";
import Pagination from "../../components/Pagination/pagination";
import { indicatorsSimpleFiltred } from "../../api/indicators";
import { registerUserLogs } from "../../api/userLogs";
import Drawer from '@mui/material/Drawer';

export default function SimulationAudiction() {
    const { id } = useParams();
    const [update, setUpdate] = useState<boolean>(false);
    const [updated, setUpdated] = useState<boolean>(false);
    const [categories, setCategories] = useState<any>([]);
    const [nameStage, setNameStage] = useState<any>([]);
    const [selectedStage, setSelectedStage] = useState<number>(0);
    const [stage, setStage] = useState('');
    const [requirement, setRequirement] = useState('');
    const [activeLoading, setActiveLoading] = useState<boolean>(true);
    const [inputService, setInputService] = useState<boolean>(false);
    const [pageActual, setPageActual] = useState<number>(0);
    const [pageFinal, setPageFinal] = useState<number>(0);
    const [valueButton, setValueButton] = useState<number>(0);
    const [indexListPages, setIndexListPages] = useState<number>(0);
    const listIndex: any = []
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');
    const [simulationRequestFiltred, setSimulationRequestFiltred] = useState<any>([]);
    const [simulationId, setSimulationId] = useState<number>(0);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const handlePage = (index: number) => {
        setPageActual(index);
        setValueButton(index);
    }

    const handlePrevPage = () => {
        setIndexListPages(indexListPages-1)
    }

    const handleNextPage = () => {
        setIndexListPages(indexListPages+1)
    }

    const handleDefineIdStage = (event: any) => {
        const value = event.target.getAttribute('data-value');
        setSelectedStage(value)
        setPageActual(0);
    }

    const handleInputService = (event: any) => {
        let responseSelect = true ? event.target.value === true || event.target.value ==="true" : false;
        setInputService(responseSelect);
    }

    const handleBack = () => {
        window.location.href = '/home-page'
    }

    const idParams = id ? parseInt(id) : 0;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const stagesRequest = await StageCategoriesSimple(id);
                const categoriesRequest = await categorieSimpleFiltred(id);
                setCategories(categoriesRequest)
                setNameStage(stagesRequest)
                const spreadsheetsRequest = await filtredSimulation(null);
                if (spreadsheetsRequest.length === 0) {
                    const indicatorsRequest = await indicatorsSimpleFiltred(selectedStage);
                    for (var indicator of indicatorsRequest) {
                        await RegisterSimulation(indicator.stage_id, indicator.id)
                    }
                    window.location.reload();
                } else {
                    setActiveLoading(true);
                    const spreadsheetsRequestFiltred = await filtredSimulation(selectedStage);
                    setSimulationRequestFiltred(spreadsheetsRequestFiltred)
                }
                
                if (nameStage != null || nameStage.length > 0) {
                    setActiveLoading(false)
                }
                setUpdate(false);
            } catch (error) {
                console.error('Erro ao buscar projeto:', error);
            }
        };

        fetchData();
    }, [selectedStage, updated]);

    useEffect(() => {
        const fetchData = async () => {
            setPageFinal(simulationRequestFiltred.length > 0 ? simulationRequestFiltred.length - 1 : 0);
            setStage(simulationRequestFiltred.length > 0 ? simulationRequestFiltred[pageActual].stage_id_stage : '')
            setRequirement(simulationRequestFiltred.length > 0 ? simulationRequestFiltred[pageActual].indicators_id_requirements : '')
            setInputService(simulationRequestFiltred.length > 0 ? (simulationRequestFiltred[pageActual].response_user === null ? 1 : simulationRequestFiltred[pageActual].response_user) : '')
            setSimulationId(simulationRequestFiltred.length > 0 ? simulationRequestFiltred[pageActual].id : 0)
        }

        fetchData();
    }, [pageActual, simulationRequestFiltred])

    useEffect(() => {
        setUpdate(false);
    }, [pageActual]);

    let indexPage;

    if (pageFinal > 0) {
        for (indexPage = 0; indexPage < (pageFinal+1); indexPage += 2) {
            listIndex.push([indexPage, indexPage+1])
        }
    }

    const handleSubmitSimulation = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveLoading(true)

        const edit: any = await EditSimulation(simulationId, inputService);
        setUpdated(true);

        if (updated) {
            setActiveLoading(false);
        } 
        
        if (edit.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Salvo!');
            setMessageRequest('Registro salvo com sucesso.');
            registerUserLogs("Realizando simulação.");
            setTimeout(function() {
                setUpdated(false);
            }, 5000);
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Verifique os dados e tente novamente.');
            setTimeout(function() {
                setUpdated(false);
            }, 5000);
        }
    }

    const [activeEdit, setActiveEdit] = useState(false);

    return (
        <div className="register-spreadsheet">
            <Navbar title="Sistema AMZ" user={true} buttonSave={true} functionBack={handleBack} functionSave={handleSubmitSimulation} menu={toggleDrawer(true)} />
            <div className='container-sidebar-register'>
                <div className="sidebar">
                    <div className="menu_stages">
                        {
                            nameStage.map((data: any, index: number) => (
                                <a className={data[1] === stage ? 'selected' : ''} data-value={data[0]} onClick={handleDefineIdStage}>{data[1]}</a>
                            ))
                        }
                    </div>
                    <ColProfile approved={false} />
                </div>
            </div>
            <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                <div className="sidebar">
                    <div className="menu_stages">
                        {
                            nameStage.map((data: any, index: number) => (
                                <a className={data[1] === stage ? 'selected' : ''} data-value={data[0]} onClick={handleDefineIdStage}>{data[1]}</a>
                            ))
                        }
                    </div>
                    <ColProfile approved={false} />
                </div>
            </Drawer>
            <section className='flex flex-col p-10 w-full lg:pl-[300px] mt-12'>
                <div className="area-message" style={activeLoading ? {display: 'flex'} : {display: 'none'}}>
                    <Loading activeProps={activeLoading}/>
                </div>
                <div className="area-message" style={activeLoading ? {display: 'flex'} : {display: 'none'}}>
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                </div>
                <div className='col-title-image-stages flex flex-row items-end mt-3' style={{ paddingLeft: 0 }}>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                        style={{width: '35px', height: '40px'}}
                    />
                    <h2 className="font-bold text-[22px] ml-2">Simulação</h2>
                </div>
                <div className="row-spreadsheet">
                    <div className="col-spreadsheet" style={{width: '700px'}}>
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Etapa:</label>
                            <p className="p-spreadsheet">
                                {stage}
                            </p>
                        </div>
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Requisito:</label>
                            <p className="p-spreadsheet">
                                {requirement}
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="divider-spreadsheet1" style={{ 'width': '80%', marginRight: "40%" }} />
                <div className="row-left-spreadsheet" style={{ marginLeft: 0 }}>
                    <div className="row-spreadsheet" style={{width: '300px'}}>
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Atende?</label>
                            <select className="select-service" value={String(inputService)} onChange={handleInputService}>
                                <option value={String(true)}>
                                    Sim
                                </option>
                                <option value={String(false)}>
                                    Não
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row-pagination" style={pageFinal > 1 ? {display: "flex"} : {display: "none"}}>
                    {pageFinal > 0 && (
                        <Pagination handlePrevPage={handlePrevPage} indexListPages={indexListPages} handleNextPage={handleNextPage} maxIndex={listIndex.length-1} valueButton={valueButton} valueButton1={listIndex[indexListPages][0]} functionButton1={() => handlePage(listIndex[indexListPages][0])} valueButton2={listIndex[indexListPages][1]} functionButton2={() => handlePage(listIndex[indexListPages][1])} pageFinal={pageFinal} functionButtonFinal={() => handlePage(pageFinal)}/>
                    )}
                </div>
            </section>
        </div>
    )
}