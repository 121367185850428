// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.col-profile button {
  width: 70px;
  margin: 0;
}

.button-logout {
    width: auto;
    background-color: #006633;
    color: #fff;
    height: 30px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
}

.col-message-approve {
  width: 1200px !important;
  height: 500px !important;
  z-index: 999;
  display: flex;
  justify-content: center;
  margin-left: 1200px;
}

.p-npca {
  margin-top: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ColProfile/colProfile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,SAAS;AACX;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".col-profile {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-bottom: 40px;\r\n}\r\n\r\n.col-profile button {\r\n  width: 70px;\r\n  margin: 0;\r\n}\r\n\r\n.button-logout {\r\n    width: auto;\r\n    background-color: #006633;\r\n    color: #fff;\r\n    height: 30px;\r\n    border: none;\r\n    border-radius: 20px;\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n    cursor: pointer;\r\n    margin-top: 10px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.col-message-approve {\r\n  width: 1200px !important;\r\n  height: 500px !important;\r\n  z-index: 999;\r\n  display: flex;\r\n  justify-content: center;\r\n  margin-left: 1200px;\r\n}\r\n\r\n.p-npca {\r\n  margin-top: 20px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
