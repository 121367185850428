import React from "react";
import axios from 'axios';

export async function dataCompany(user_id: string) {
    try {
        const reponseCompany = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user-relatory/',
            {
                user_id: parseInt(user_id)
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (reponseCompany.status === 200) {
            return reponseCompany.data['dados'];
        }
    } catch (error) {
        return []
    }
}