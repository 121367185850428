import React from "react";
import axios from 'axios';

export async function editAddress(inputIdCep: number, inputCep: string, inputAddress: string, inputNum: number, inputComplement: string, inputNeighborhood: string, inputCity: string, inputUf: string, inputCountry: string) {
    try {
        const responseAddress = await axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/address/',
            {
                id: inputIdCep,
                cep: inputCep,
                address: inputAddress,
                num: inputNum,
                complement: inputComplement ? inputComplement : "null",
                neighborhood: inputNeighborhood,
                city: inputCity,
                uf: inputUf,
                country: inputCountry,
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseAddress.status === 200) {
            return responseAddress;
        }
    } catch (error) {
        return []
    }
}

export async function registerAddress(inputCep: string, inputAddress: string, inputNum: number | null, inputComplement: string, inputNeighborhood: string, inputCity: string, inputUf: string, inputCountry: string) {
    try {
        const responseRegister = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/address/',
            {
                cep: inputCep,
                address: inputAddress,
                num: inputNum,
                complement: inputComplement !== "" ? inputComplement : null,
                neighborhood: inputNeighborhood,
                city: inputCity,
                uf: inputUf,
                country: inputCountry,
            });
        if (responseRegister.status === 201) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}