import React from "react";
import axios from 'axios';

export async function activeUser(emailProfile: string) {
    try {
        const reponseActive = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/admin-amz/',
            {
                email: emailProfile,
                is_active: true
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (reponseActive.status === 200) {
            return reponseActive;
        }
    } catch (error) {
        return []
    }
}

export async function editUser(nameProfile: string, emailProfile: string, phoneProfile: string, cpfProfile: string, companyProfile: string, cnpjProfile: string, officeProfile: string, idCepProfile: number, isActive: boolean) {
    try {
        const responseEditUser = await axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user/',
            {
                name: nameProfile,
                email: emailProfile,
                phone: phoneProfile,
                cpf: cpfProfile,
                company: companyProfile,
                cnpj: cnpjProfile === null ? "null" : cnpjProfile,
                office: officeProfile,
                cep_address: idCepProfile,
                is_active: isActive
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseEditUser.status === 200) {
            return responseEditUser;
        }
    } catch (error) {
        return []
    }
}

export async function editProfile(nameProfile: string, emailProfile: string, phoneProfile: string, cpfProfile: string, companyProfile: string, cnpjProfile: string, groups: string, officeProfile: string, idCepProfile: number, isActive: boolean) {
    try {
        const responseEditUser = await axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/admin-amz/',
            {
                name: nameProfile,
                email: emailProfile,
                phone: phoneProfile,
                cpf: cpfProfile,
                company: companyProfile,
                cnpj: cnpjProfile === null ? "null" : cnpjProfile,
                groups: groups, 
                office: officeProfile,
                is_active: isActive
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseEditUser.status === 200) {
            return responseEditUser;
        }
    } catch (error) {
        return []
    }
}