import path from 'path';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const profile = localStorage.getItem('profile');

  const optionsAdmin = [
    {
      title: 'Página Inicial',
      to: '/home-page',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
      </svg>
    },
    {
      title: 'Avaliação de Campo',
      to: '/field-evaluation',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
      </svg>
    },
    {
      title: 'Etapas',
      to: '/steps',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
      </svg>
    },
    {
      title: 'Relatórios',
      to: '/reports',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
      </svg>
    },
    {
      title: 'Simulação',
      to: '/simulation',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" stroke="black" strokeWidth="2" className="size-6 mr-2" width="24" height="24">

        <rect x="8" y="8" width="40" height="48" fill="white" stroke="black" />
        <line x1="12" y1="12" x2="12" y2="52" stroke="black" strokeWidth="2"/>
        <line x1="16" y1="12" x2="16" y2="52" stroke="black" strokeWidth="2"/>
        <line x1="20" y1="12" x2="20" y2="52" stroke="black" strokeWidth="2"/>
        
        <polygon points="48,16 56,8 60,12 52,20" fill="white" stroke="black" />
        <line x1="48" y1="16" x2="52" y2="20" stroke="black" strokeWidth="2"/>
        <line x1="56" y1="8" x2="60" y2="12" stroke="black" strokeWidth="2"/>
        <line x1="54" y1="10" x2="58" y2="14" stroke="black" strokeWidth="2"/>
      </svg>
    },
    {
      title: 'Monitoramento',
      to: '/monitoring',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
        <path d="M2 12a10 10 0 1 0 20 0 10 10 0 0 0-20 0zm10 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-4-8a4 4 0 1 1 8 0 4 4 0 0 1-8 0zm6 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0z"/>
      </svg>
    },
    {
      title: 'Documentação',
      to: '/certification',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 2c.21 0 .42.04.62.1l1.7.51 1.27.75.72 1.27.5 1.7c.07.2.1.42.1.62v2.18l.55.56 2.28.57c.2.05.4.16.56.33.2.19.32.47.32.76v2.22c0 .29-.12.57-.32.76-.16.17-.36.28-.56.33l-2.28.57-.55.56V17.4c0 .21-.04.42-.1.62l-.51 1.7-.72 1.27-1.27.72-1.7.5a1.5 1.5 0 0 1-1.24 0l-1.7-.5-1.27-.72-.72-1.27-.5-1.7a1.5 1.5 0 0 1 0-1.24l.5-1.7V12l-.56-.56-2.28-.57a1.5 1.5 0 0 1-.56-.33c-.19-.19-.32-.47-.32-.76V7.12c0-.29.12-.57.32-.76.16-.17.36-.28.56-.33l2.28-.57.56-.56V4.6c0-.21.04-.42.1-.62l.51-1.7L9.9 2.1 11.6 2c.2-.06.41-.1.62-.1zM12 6.75c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25s1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25zm0 10.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
      </svg>
    },
    {
      title: 'Usuários',
      to: '/users',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
      </svg>
    },
  ];

  const optionsAuditorCamara = [
    {
      title: 'Página Inicial',
      to: '/home-page',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
      </svg>
    },
    {
      title: 'Avaliação de Campo',
      to: '/field-evaluation',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
      </svg>
    },
    {
      title: 'Etapas',
      to: '/steps',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
      </svg>
    },
    {
      title: 'Simulação',
      to: '/simulation',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" stroke="black" strokeWidth="2" className="size-6 mr-2" width="24" height="24">

        <rect x="8" y="8" width="40" height="48" fill="white" stroke="black" />
        <line x1="12" y1="12" x2="12" y2="52" stroke="black" strokeWidth="2"/>
        <line x1="16" y1="12" x2="16" y2="52" stroke="black" strokeWidth="2"/>
        <line x1="20" y1="12" x2="20" y2="52" stroke="black" strokeWidth="2"/>
        
        <polygon points="48,16 56,8 60,12 52,20" fill="white" stroke="black" />
        <line x1="48" y1="16" x2="52" y2="20" stroke="black" strokeWidth="2"/>
        <line x1="56" y1="8" x2="60" y2="12" stroke="black" strokeWidth="2"/>
        <line x1="54" y1="10" x2="58" y2="14" stroke="black" strokeWidth="2"/>
      </svg>
    },
    {
      title: 'Documentação',
      to: '/certification',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 2c.21 0 .42.04.62.1l1.7.51 1.27.75.72 1.27.5 1.7c.07.2.1.42.1.62v2.18l.55.56 2.28.57c.2.05.4.16.56.33.2.19.32.47.32.76v2.22c0 .29-.12.57-.32.76-.16.17-.36.28-.56.33l-2.28.57-.55.56V17.4c0 .21-.04.42-.1.62l-.51 1.7-.72 1.27-1.27.72-1.7.5a1.5 1.5 0 0 1-1.24 0l-1.7-.5-1.27-.72-.72-1.27-.5-1.7a1.5 1.5 0 0 1 0-1.24l.5-1.7V12l-.56-.56-2.28-.57a1.5 1.5 0 0 1-.56-.33c-.19-.19-.32-.47-.32-.76V7.12c0-.29.12-.57.32-.76.16-.17.36-.28.56-.33l2.28-.57.56-.56V4.6c0-.21.04-.42.1-.62l.51-1.7L9.9 2.1 11.6 2c.2-.06.41-.1.62-.1zM12 6.75c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25s1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25zm0 10.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
      </svg>
    },
  ];

  const optionsClient = [
    {
      title: 'Página Inicial',
      to: '/home-page',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="size-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
      </svg>
    },
    {
      title: 'Simulação',
      to: '/simulation',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="none" stroke="black" strokeWidth="2" className="size-6 mr-2" width="24" height="24">

        <rect x="8" y="8" width="40" height="48" fill="white" stroke="black" />
        <line x1="12" y1="12" x2="12" y2="52" stroke="black" strokeWidth="2"/>
        <line x1="16" y1="12" x2="16" y2="52" stroke="black" strokeWidth="2"/>
        <line x1="20" y1="12" x2="20" y2="52" stroke="black" strokeWidth="2"/>
        
        <polygon points="48,16 56,8 60,12 52,20" fill="white" stroke="black" />
        <line x1="48" y1="16" x2="52" y2="20" stroke="black" strokeWidth="2"/>
        <line x1="56" y1="8" x2="60" y2="12" stroke="black" strokeWidth="2"/>
        <line x1="54" y1="10" x2="58" y2="14" stroke="black" strokeWidth="2"/>
      </svg>
    },
    {
      title: 'Documentação',
      to: '/certification',
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mr-2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 2c.21 0 .42.04.62.1l1.7.51 1.27.75.72 1.27.5 1.7c.07.2.1.42.1.62v2.18l.55.56 2.28.57c.2.05.4.16.56.33.2.19.32.47.32.76v2.22c0 .29-.12.57-.32.76-.16.17-.36.28-.56.33l-2.28.57-.55.56V17.4c0 .21-.04.42-.1.62l-.51 1.7-.72 1.27-1.27.72-1.7.5a1.5 1.5 0 0 1-1.24 0l-1.7-.5-1.27-.72-.72-1.27-.5-1.7a1.5 1.5 0 0 1 0-1.24l.5-1.7V12l-.56-.56-2.28-.57a1.5 1.5 0 0 1-.56-.33c-.19-.19-.32-.47-.32-.76V7.12c0-.29.12-.57.32-.76.16-.17.36-.28.56-.33l2.28-.57.56-.56V4.6c0-.21.04-.42.1-.62l.51-1.7L9.9 2.1 11.6 2c.2-.06.41-.1.62-.1zM12 6.75c-.69 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25s1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25zm0 10.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
      </svg>
    },
  ];

  const options = profile === 'administrador' ? optionsAdmin : profile === 'auditor' || profile === 'camara' ? optionsAuditorCamara : optionsClient;  

  return (
    <div className="flex h-screen overflow-hidden">
      {/* botão para abrir/fechar a sidebar em dispositivos móveis */}
      <button 
        className="md:hidden p-2 text-black bg-transparent fixed top-0 left-0 z-20" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-10">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>

      {/* overlay para quando a sidebar estiver aberta em dispositivos móveis */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black opacity-50 z-10" 
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* sidebar */}
      <div 
        className={`fixed top-0 left-0 h-full w-64 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition-transform duration-200 ease-in-out bg-gray-50 text-black flex flex-col z-20 overflow-y-auto`}
      >
        {/* primeira seção */}
        <div className="p-4">
          <Link to="/home-page" onClick={() => setIsOpen(false)} className="flex items-center mb-1 p-2 bg-transparent ">
              <img src="logo.png" alt="Logo" className="h-auto w-6 mr-2" />
              <h2 className="text-xl font-bold">Sistema AMZ</h2>
            </Link>
          <ul>
            {options.map((value, index) => (
              <li className="mb-2 hover:text-gray-700 p-2 rounded">
                <Link to={value.to} onClick={() => setIsOpen(false)} className="flex items-center">
                {value.icon}
                {value.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* separador */}
        <hr className="border-gray-300 my-4" />

        {/* Segunda seção */}
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Ajustes</h2>
          <ul>
            <li className="mb-2 hover:text-gray-700 p-2 rounded">
              <Link to="/profile" onClick={() => setIsOpen(false)} className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
              </svg>
              Perfil
              </Link>
            </li>
            <li className="mb-2 hover:text-gray-700 p-2 rounded">
              <Link to="/report-problem" onClick={() => setIsOpen(false)} className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
              </svg>
              Relatar Problema
              </Link>
            </li>
            <li className="mb-2 hover:text-gray-700 p-2 rounded">
              <Link to="/settings" onClick={() => setIsOpen(false)} className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
              Configurações
              </Link>
            </li>
          </ul>
        </div>
        
        {/* rodapé */}
        <div className="flex justify-center items-center">
          <p className="fixed bottom-5 text-center text-xs">©NPCA</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
