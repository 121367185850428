import axios from 'axios';

export async function registerUserLogs(action: string) {
    try {
        const indicatorRequest = await axios.put(process.env.REACT_APP_URL_API_AMZ+'api/user-logs/',
            {
                action: action
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }                       
            });
        if (indicatorRequest.status === 201) {
            return indicatorRequest
        } else {
            window.location.href = "/";
        }       
    } catch (error) {
        window.location.href = "/";
        return []
    }
}

export async function viewUserLogs(day?: string, month?: string, year?: string) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/user-logs/',
            {
                day: day,
                month: month,
                year: year  
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }                       
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest.data;
        }      
    } catch (error) {
        return []
    }
}