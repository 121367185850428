import React, { useRef } from 'react';
import './fileUploadArea.css';

type FileUploadAreaProps = {
  onFileSelect: any,
  nameFile: string,
  marginTop?: string
}

const FileUploadArea = ({ onFileSelect, nameFile, marginTop }: FileUploadAreaProps) => {
  const dropAreaRef: any = useRef(null);

  const handleDragOver = (event: any) => {
    event.preventDefault();
    dropAreaRef.current.classList.add('drag-over');
  };

  const handleDragLeave = () => {
    dropAreaRef.current.classList.remove('drag-over');
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    dropAreaRef.current.classList.remove('drag-over');

    const file = event.dataTransfer.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <div
      className="file-upload-area"
      ref={dropAreaRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{ 'marginTop': 0 }}
    >
      <input
        className="input-upload"
        type="file"
        onChange={(e: any) => onFileSelect(e.target.files[0])}
      />
      <p className='p-area-upload'>{!nameFile ? 'arraste aqui o arquivo ou clique' : nameFile}</p>
    </div>
  );
};

export default FileUploadArea;
