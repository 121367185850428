import React, { useEffect, useState } from 'react';
import { filterProjectsFinisheds } from '../api/spreadsheets';

const FinalFE = () => {
  const [projectsData, setProjectsData] = useState([
    { id: 1, name: '', stakeholder_name: '', created_at: '', finished: '' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const projectsDataRequest = await filterProjectsFinisheds(true);
      setProjectsData(projectsDataRequest);
    };

    fetchData();
  }, []);

  function formatDate(date: string) {
    const dateData = new Date(date);

    return `${parseInt(String(dateData.getDay())) < 10 ? '0'+String(dateData.getDay()) : dateData.getDay()}/${parseInt(String(dateData.getMonth())) < 10 ? '0'+String(dateData.getMonth()) : dateData.getMonth()}/${dateData.getFullYear()}`;
  }

  const handleViewProfile = (id: number) => {
    console.log(`Visualizar perfil do usuário com ID: ${id}`);
    // lógica para visualizar o perfil do usuário
  };

  return (
    <div className="mt-20 p-4 md:p-8">
      <h1 className="text-2xl font-bold mb-4 text-center">Avaliações de Campo Finalizadas</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-300">Título</th>
              <th className="py-2 px-4 border-b border-gray-300">Cliente</th>
              <th className="py-2 px-4 border-b border-gray-300">Data</th>
              <th className="py-2 px-4 border-b border-gray-300">Status</th>
              <th className="py-2 px-4 border-b border-gray-300">Ações</th>
            </tr>
          </thead>
          <tbody>
            {projectsData.map((project) => (
              <tr key={project.id} className="text-center">
                <td className="py-2 px-4 border-b border-gray-300">{project.name}</td>
                <td className="py-2 px-4 border-b border-gray-300">{project.stakeholder_name}</td>
                <td className="py-2 px-4 border-b border-gray-300">{formatDate(project.created_at)}</td>
                <td className="py-2 px-4 border-b border-gray-300">finalizado</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button 
                    className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                    onClick={() => handleViewProfile(project.id)}
                  >
                    Visualizar
                  </button>
                  {/* <button 
                    className="ml-5 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                    onClick={() => handleViewProfile(user.id)}
                  >
                    Baixar
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FinalFE;
