import React from "react";
import axios from 'axios';

export async function logout() {
    try {
        const logout = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/logout/',
            {
                refresh: localStorage.getItem('refresh_token')
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (logout.status === 200) {
            return logout;
        }
    } catch (error) {
        return []
    }
}