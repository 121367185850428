import axios from 'axios';

export async function registerReport(formData: any) {
    try {
        const responseRegister = await axios.put(process.env.REACT_APP_URL_API_AMZ+'api/reports/',
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseRegister.status === 201) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}

export async function registerAccomplishedsTerms(title: string, accomplished_relatory: any, term_relatory: any) {
    try {
        const responseRegister = await axios.put(process.env.REACT_APP_URL_API_AMZ+'api/reports/acomplisheds-terms/',
            {
                title: title,
                accomplished_relatory:  accomplished_relatory,
                term_relatory: term_relatory
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseRegister.status === 201) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}

export async function returnReports() {
    try {
        const responseReports = await axios.get(process.env.REACT_APP_URL_API_AMZ+'api/reports/',
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseReports.status === 200) {
            return responseReports.data;
        }
    } catch (error) {
        return []
    }
}

export async function filtredReport(id: string) {
    try {
        const responseReports = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/reports/', 
            {
                id: parseInt(id)
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseReports.status === 200) {
            return responseReports.data;
        }
    } catch (error) {
        return []
    }
}

export async function editReport(formData: any) {
    try {
        const responseRegister = await axios.patch(process.env.REACT_APP_URL_API_AMZ+'api/reports/',
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            });
        if (responseRegister.status === 200) {
            return responseRegister;
        }
    } catch (error) {
        return []
    }
}