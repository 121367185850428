import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header'; 
import { cnpjUser } from '../api/cnpjUsers';
import { categories, stages } from '../api/categoriesStages';
import { registerProject } from '../api/spreadsheets';
import { registerUserLogs } from '../api/userLogs';
import MessageRequest from '../components/CardMessage/MessageRequest/messageRequest';

const FieldEvaluation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [inputName, setinputName] = useState<string>('');
  const [inputStakeholder, setinputStakeholder] = useState<string>('');
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [dataStages, setDataStages] = useState<any>([]);
  const [selectedStages, setSelectedStages] = useState<number[]>([]);
  const [selectedCategoriesData, setSelectedCategoriesData] = useState<number[]>([]);
  const [dataUserCnpj, setDataUserCnpj] = useState([]);
  const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
  const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
  const [messageRequest, setMessageRequest] = useState<string>('');

  useEffect(() => {
      const cnpjData = async () => {
          try {
              const cnpjRequest: any = await cnpjUser();
              setDataUserCnpj(cnpjRequest);
          } catch(e) {
              return [];
          }
      }
      cnpjData();
  }, []);

  useEffect(() => {
      const fetchData = async () => {
          const responseStage = await stages(); 

          setDataStages(responseStage)

          const responseCategoriesData = await categories();
          
          setCategoriesData(responseCategoriesData)
      }

      fetchData();
  }, []);

  const handleInputStakeholder = (event: any) => {
      setinputStakeholder(event.target.value);
  }

  const handleCheckboxChangeStages = (stageId: number) => {
      if (selectedStages.includes(stageId)) {
          setSelectedStages(selectedStages.filter(id => id !== stageId));
      } else {
          setSelectedStages([...selectedStages, stageId]);
      }
  };

  const handleCheckboxChangeCategoriesData = (categoryId: number) => {
      if (selectedCategoriesData.includes(categoryId)) {
          setSelectedCategoriesData(selectedCategoriesData.filter(id => id !== categoryId));
      } else {
          setSelectedCategoriesData([...selectedCategoriesData, categoryId]);
      }
  };

  const handleInputName = (event: ChangeEvent<HTMLInputElement>) => {
      setinputName(event.target.value);
  }

  const navigate = useNavigate();
  const location = useLocation();
  const hideSidebarAndHeader = location.pathname === '/' || location.pathname === '/login';

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  type CheckboxSections = 'selos' | 'etapas' | 'categoriasEspecificas';


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const responseRegisterProject: any = await registerProject(inputName, inputStakeholder, selectedCategoriesData, selectedStages);

    if (responseRegisterProject.status === 201) {
      setIsModalOpen(false);
      setActiveMessageRequest(false);
      setActiveMessageRequest(true);
      setTitleMessageRequest('Sucesso:');
      setMessageRequest('Projeto criado com sucesso.');
      registerUserLogs("Criou projeto para auditoria.");
  } else {
      setActiveMessageRequest(false);
      setActiveMessageRequest(true);
      setTitleMessageRequest('Erro:');
      setMessageRequest('Cadastro inválido. Revise os dados e tente novamente.')
  }
  };

  return (
    <div className="flex h-screen">
      <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
      <div className="flex-1 flex flex-col">
        {!hideSidebarAndHeader && <Header />}
        <div className={`flex-1 p-8 ${!hideSidebarAndHeader ? 'mt-16' : ''}`}>
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Avaliação de Campo</h1>
            <p className="mb-6">Conteúdo da Avaliação de Campo.</p>
            <div className="flex flex-wrap justify-center gap-4">
              <button
                onClick={() => setIsModalOpen(true)}
                className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300"
              >
                Criar Nova Avaliação
              </button>
              <a href="/consultFE">
                <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
                  Consultar Avaliação
                </button>
              </a>
              <a href="/finalFE">
                <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
                  Avaliações Finalizadas
                </button>
              </a>
            </div>
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
                <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-lg w-96">
                  <h2 className="text-xl mb-4">Nova Auditoria</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sheetName">
                        Título
                      </label>
                      <input
                        type="text"
                        id="sheetName"
                        name="sheetName"
                        value={inputName}
                        onChange={handleInputName}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sheetName">Responsável</label>
                        <select 
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                          value={inputStakeholder} 
                          onChange={handleInputStakeholder}
                        >
                          <option value="">Selecione...</option>
                          {dataUserCnpj.map((user: any, index: number) => (
                              <option key={index} value={user.id}>
                                  {user.name}
                              </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <h3 className="text-gray-700 text-sm font-bold mb-2">Etapas</h3>
                      <ul style={{margin: 0, 'marginLeft': '30px'}}>
                        {dataStages.map((stage: any) => (
                          <li key={stage.id}>
                              <label style={{"fontSize": '14px', 'display': 'flex', 'alignItems': 'center', 'color': "#000000"}}>
                                  <input
                                      type="checkbox"
                                      checked={selectedStages.includes(stage.id)}
                                      onChange={() => handleCheckboxChangeStages(stage.id)}
                                  />
                                  <p className='mb-0 ml-2'>{stage.stage}</p>
                              </label>
                          </li>
                        ))}
                    </ul>
                    </div>
                    <div className="mb-4">
                      <h3 className="text-gray-700 text-sm font-bold mb-2">Categorias Específicas</h3>
                      <ul style={{margin: 0, 'marginLeft': '30px'}}>
                        {categoriesData.map((category: any) => (
                          <li key={category.id}>
                              <label style={{"fontSize": '14px', 'display': 'flex', 'alignItems': 'center', 'color': "#000000"}}>
                                  <input
                                      type="checkbox"
                                      checked={selectedCategoriesData.includes(category.id)}
                                      onChange={() => handleCheckboxChangeCategoriesData(category.id)}
                                  />
                                  <p className='mb-0 ml-2'>{category.categorie}</p>
                              </label>
                          </li>
                        ))}
                    </ul>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => setIsModalOpen(false)}
                        className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2"
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded"
                      >
                        Cadastrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldEvaluation;
