import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageRequest from '../components/CardMessage/MessageRequest/messageRequest';
import { resetPassword } from '../api/resetPassword';
import { registerUserLogs } from '../api/userLogs';

export default function ResetPasswordScreen() {
  const [inputEmail, setinputEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
  const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
  const [messageRequest, setMessageRequest] = useState<string>('');
  const navigate = useNavigate();

  const handleInputEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setinputEmail(event.target.value);
    setErrorEmail(null);
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputEmail.trim() === '') {
      setErrorEmail('Campo obrigatório.')
      return;
    } else {
      setErrorEmail('')
    }

    const responseReset: any = await resetPassword(inputEmail);
    if (responseReset.status === 200) {
      setActiveMessageRequest(true);
      setTitleMessageRequest('Sucesso:');
      setMessageRequest('E-mail enviado com sucesso. Verifique seus e-mails.')
      registerUserLogs("Enviou e-mail solicitando troca de senha.");
    } else {
      setActiveMessageRequest(true);
      setTitleMessageRequest('Erro:');
      setMessageRequest('Não foi possível enviar o e-mail. Por favor verifique-o, e garanta que você é cadastrado no sistema.')
    }
  }

  return (
    <div>
      <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
      <div className="flex items-center justify-center min-h-screen bg-white p-2">
        <div className="bg-green-700 p-8 rounded-lg shadow-md w-full sm:w-96">
          <h2 className="text-2xl font-semibold text-white text-center mb-4">TROCAR SENHA</h2>
          <form>
            <label className="block text-white">email</label>
            <input type="text" onChange={handleInputEmail} className={errorEmail && errorEmail !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500" }/>
            <span>{errorEmail}</span>
            <div className="flex justify-center">
              <button className="bg-white text-green-700 px-4 py-2 rounded-md transition-colors duration-300 hover:bg-gray-300 hover:text-white focus:outline-none focus:bg-gray-300 focus:text-white border-50 border-green-700" onClick={handleSubmit}>enviar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
